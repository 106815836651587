import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Public} from './routes';
import {Login} from './screens/ayth';
import {Private} from './routes/Private';
import {Home} from './screens/home';
import {Region} from './screens/region';
import {LeaderPage} from './screens/leader';

function App() {
    return (
        <Routes>
            <Route path={'/login'} element={<Public/>}>
                <Route path={''} element={<Login/>}/>
            </Route>
            <Route path={''} element={<Private/>}>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'/region/:regionId'} element={<Region/>}/>
                <Route path={'/leader/:leaderId'} element={<LeaderPage/>}/>
            </Route>
        </Routes>
    );
}

export default App;
