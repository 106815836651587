import {MutableRefObject, useEffect, useRef, useState} from 'react';

export const useResize = <T extends HTMLElement>(): { ref: MutableRefObject<T | null>, rect: DOMRect | null } => {
    const ref = useRef<T | null>(null);
    const [rect, setRect] = useState<DOMRect | null>(null);

    useEffect(() => {
        const element = ref.current;

        if (!element) return;

        const resizeObserver = new ResizeObserver(entries => {
            if (entries[0]) {
                setRect(entries[0].contentRect);
            }
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);

    return {ref, rect};
};
