import styled from 'styled-components';

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 300px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  gap: 10px;
  padding: 10px 10px 10px 10px;
  margin: 20px 0;

  position: relative;
`;

export const BraWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 12%;
  position: relative;
  z-index: 2;
`;

export const Bar = styled.div.withConfig({
    shouldForwardProp: prop => !['height', 'background'].includes(prop)
})<{ height: string; background: string }>`
  width: 100%;
  margin: 0 10px;
  text-align: center;
  background: ${({background}) => background};
  height: ${({height}) => height};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border: 1px solid var(--color-text-light);
  box-shadow: var(--box-shadow);

  display: flex;
  flex-direction: column;
  justify-content: center;
`;


export const Label = styled.div`
  width: 100%;
  margin: 0 10px;
  text-align: center;
`;

export const GridRow = styled.span.withConfig({
    shouldForwardProp: prop => !['text'].includes(prop)
})<{ text: string }>`
  position: absolute;
  width: 90%;
  height: 1px;
  border-bottom: 1px solid #e7e7e7;
  padding-left: 25px;
  display: flex;
  align-items: center;
  bottom: ${({text}) => text};
  z-index: 1;

  &:after {
    content: ${({text}) => `"${text}"`};
    width: 20px;
    display: inline-block;
    font-family: var(--font);
    font-size: 14px;
    color: #e7e7e7;
    font-weight: 500;
    position: absolute;
    left: -4%;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  gap: 10px;
  padding: 10px 10px 10px 10px;
  margin-top: 20px;

  position: relative;
`;

export const BarInfo = styled.div.withConfig({
    shouldForwardProp: prop => !['background'].includes(prop)
})<{ background: string }>`
  width: 100%;
  margin: 0 10px;
  text-align: center;
  background: ${({background}) => background};
  height: 100%;
  border-radius: 12px;
  border: 1px solid var(--color-text-light);
  box-shadow: var(--box-shadow);

  display: flex;
  flex-direction: column;
  justify-content: center;
`;
