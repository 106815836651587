import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError,} from '@reduxjs/toolkit/query/react';
import {getGlobalAbortController} from './abortController';

//export const BASE_URL: string = 'https://ratingkpi.ru/api';
export const BASE_URL: string = 'https://apifoodbox.ru/apiMunicipal';

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: 'same-origin',
    prepareHeaders: (headers, {getState}) => {
        const state = getState() as any;

        if (state?.auth.id_con) {
            headers.set('id_con', `${state?.auth.id_con}`);
        }
        return headers;
    },
    fetchFn: async (input, init) => {
        try {
            const response = await fetch(input, {
                ...init,
                signal: getGlobalAbortController().signal,
            });

            if (response.ok && response.headers.get('Content-Type')?.startsWith('image/')) {
                const blob = await response.blob();
                return new Response(blob); // Вернуть Blob как Response
            }

            return response;
        } catch (error: any) {
            if (error.name === 'AbortError') {
                // console.log('Fetch aborted');
                // Возможно, вы захотите выполнить дополнительные действия при прерывании запроса
            } else {
                //   console.error('Fetch error:', error);
            }
            throw error;
        }
    },
});
//
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs,
    unknown,
    FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions) as any;
    if (result.error && result.error.status === 401) {
        const dispatch = api.dispatch;
        // dispatch(logout());
    }

    if (result.error && result.error.data) {
        if (result.error.data.message.includes('Неактуальная сессия')) {
            const dispatch = api.dispatch;
            // dispatch(logout());
        }
    }

    return result;
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    tagTypes: [],
    endpoints: build => ({})
});

