import React, {useState} from 'react';
import {SimpleLayout} from '../../layouts/SimpleLayout';
import {LeaderCard, LeaderReport, ToggleBlock} from './compoments';
import {useParams} from 'react-router-dom';
import {Text} from '../../fields';
import styled from 'styled-components';
import {WorkActivity} from './compoments/WorkActivity';
import {risingOptions} from './config';
import {useAppSelector} from '../../hooks';

const leader1 = require('../../assets/images/directors/director-1.jpeg');
const leader2 = require('../../assets/images/directors/director-2.jpeg');
const leader3 = require('../../assets/images/directors/director-3.jpeg');
const leader4 = require('../../assets/images/directors/director-4.jpeg');

export type Leader = {
    img: string;
    id: number;
    name: string;
    lastname: string;
    surname: string;
}

const leaders: Leader[] = [
    {img: leader1, id: 1, name: 'Иван', lastname: 'Федорович', surname: 'Гализдра'},
    {img: leader2, id: 2, name: 'Степан', lastname: 'Александрович', surname: 'Петров'},
    {img: leader3, id: 3, name: 'Светлана', lastname: 'Сергеевна', surname: 'Пугачева'},
    {img: leader4, id: 4, name: 'Илья', lastname: 'Иванович', surname: 'Муромец'},
];

export const LeaderPage = () => {
    const {leaderId} = useParams<{ leaderId: string }>();
    const {selectSubjectName} = useAppSelector(state => state.home);

    const [risingIndex, setRisingIndex] = useState<number>(0);

    const currentLeader = leaders.find(i => i.id === Number(leaderId)) as Leader;
    return (
        <SimpleLayout
            currentPage={{name: selectSubjectName, path: ''}}
            rightSidebar={<ToggleBlock toggleIndex={risingIndex} getIndex={setRisingIndex}/>}>
            <Content>
                <Column>
                    <LeaderCard {...currentLeader}/>
                    <Head>
                        <Text as={'p'} size={18} color={'var(--color-text)'} weight={400}>Трудовая деятельность</Text>
                    </Head>
                    <WorkActivity/>
                </Column>
                <Column>
                    {risingOptions[risingIndex].map((item, index) =>
                        <LeaderReport key={index} {...item}/>)}
                </Column>
            </Content>
        </SimpleLayout>
    );
};

const Content = styled.div`
  width: 100%; //calc(100vw - 480px);
  display: flex;
  margin-left: -10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px 0;
  width: 50%;
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding: 10px;
  
  &:nth-child(1) {
    margin-left: 8px;
  }
`;

const Head = styled.div`
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  border-radius: 12px;
  padding: 20px;
`;

const RisingList = styled.div`
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
