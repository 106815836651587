import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAppSelector} from '../hooks';

export const Public = () => {
    const {isAuth} = useAppSelector(state => state.auth);

    return !isAuth ? (
        <Outlet/>
    ) : (
        <Navigate to={'/'}/>
    );
};
