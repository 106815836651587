const director_1 = require('../../assets/images/directors/director-1.jpeg');
const director_2 = require('../../assets/images/directors/director-2.jpeg');
const director_3 = require('../../assets/images/directors/director-3.jpeg');
const director_4 = require('../../assets/images/directors/director-4.jpeg');

export type RisingType = {
    positiveValue: number;
    negativeValue: number;
    text: string
}

function getRandomNumber(num: number) {
    return Math.floor(Math.random() * num);
}

export const risingOptions: RisingType[][] = [
    [
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Площадь земельных участков, предоставленных для строительства в расчете на 10 тыс. человек населения, - всего, гектаров, гектаров'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: '39. Удельная величина потребления энергетических ресурсов муниципальными бюджетными учреждениями: холодная вода, куб. метров на 1 чел'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Число субъектов малого и среднего предпринимательства, ед на 10 тыс. чел'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Доля муниципальных учреждений культуры, здания которых находятся в аварийном состоянии или требуют капитального ремонта, в общем количестве муниципальных учреждений культуры, процентов'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Доля детей в возрасте от 1 года до 6 лет, стоящих на учете для определения в муниципальные дошкольные образовательные учреждения, в общей численности детей в возрасте от 1 года до 6 лет, процентов'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Удельная величина потребления энергетических ресурсов муниципальными бюджетными учреждениями: горячая вода, куб. метров на 1 чел'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Доля населения, получившего жилые помещения и улучшившего жилищные условия в отчетном году, в общей численности населения, состоящего на учете в качестве нуждающегося в жилых помещениях, процентов'
        },

    ],
    [
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Площадь земельных участков, предоставленных для строительства, в отношении которых с даты принятия решения о предоставлении земельного участка или подписания протокола о результатах торгов (конкурсов, аукционов) не было получено разрешение на ввод в эксплуатацию: иных объектов капитального строительства - в течение 5 лет , кв. метров'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'в том числе введенная в действие за один год, кв. метров'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Удельная величина потребления энергетических ресурсов в многоквартирных домах: горячая вода, куб метров на 1 прожив-го'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Удельная величина потребления энергетических ресурсов муниципальными бюджетными учреждениями: горячая вода, куб. метров на 1 чел'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Расходы бюджета муниципального образования на содержание работников органов местного самоуправления в расчете на одного жителя муниципального образования, рублей'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Доля детей в возрасте от 1 года до 6 лет, стоящих на учете для определения в муниципальные дошкольные образовательные учреждения, в общей численности детей в возрасте от 1 года до 6 лет, процентов'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Доля протяженности автомобильных дорог общего пользования местного значения, не отвечающих нормативным требованиям, в общей протяженности автомобильных дорог общего пользования местного значения, процентов'
        },
    ],
    [
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Объем инвестиций в основной капитал (за исключением бюджетных средств) в расчете на 1 жителя, рублей'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Площадь земельных участков, предоставленных для строительства в расчете на 10 тыс. человек населения, - всего, гектаров в том числе земельных участков, предоставленных для жилищного строительства, индивидуального строительства и комплексного освоения в целях жилищного строительства, гектаров'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Удельная величина потребления энергетических ресурсов муниципальными бюджетными учреждениями: электрическая энергия, кВт. ч на 1 чел'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Расходы бюджета муниципального образования на содержание работников органов местного самоуправления в расчете на одного жителя муниципального образования, рублей'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Доля обучающихся, систематически занимающихся физической культурой и спортом, в общей численности обучающихся, процентов'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Доля выпускников муниципальных общеобразовательных учреждений, не получивших аттестат о среднем (полном) образовании, в общей численности выпускников муниципальных общеобразовательных учреждений, процентов'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'в том числе введенная в действие за один год, кв. метров'
        },
    ],
    [
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Удельная величина потребления энергетических ресурсов муниципальными бюджетными учреждениями: природный газ, куб. метров на 1 чел'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Доля выпускников муниципальных общеобразовательных учреждений, не получивших аттестат о среднем (полном) образовании, в общей численности выпускников муниципальных общеобразовательных учреждений, процентов'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Доля муниципальных дошкольных образовательных учреждений, здания которых находятся в аварийном состоянии или требуют капитального ремонта, в общем числе муниципальных дошкольных образовательных учреждений, процентов'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Среднемесячная номинальная начисленная заработная плата работников: муниципальных учреждений культуры и искусства, рублей'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Доля детей в возрасте 5 - 18 лет, получающих услуги по дополнительному образованию в организациях различной организационно-правовой формы и формы собственности, в общей численности детей данной возрастной группы, процентов'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Доля муниципальных учреждений культуры, здания которых находятся в аварийном состоянии или требуют капитального ремонта, в общем количестве муниципальных учреждений культуры, процентов'
        },
        {
            positiveValue: getRandomNumber(100),
            negativeValue: getRandomNumber(100),
            text: 'Площадь земельных участков, предоставленных для строительства в расчете на 10 тыс. человек населения, - всего, гектаров, гектаров'
        },
    ],
];


export const leaderInfo = [
    {
        id: 1,
        photo: director_1,
        name: 'Петров Иван Поликарпович',
        jobTitle: 'И.О Губернатора области',
        nationality: 'Русский',
        education: 'высшее',
        specialty: 'юрист',
        academicDegree: 'доктор наук'
    },
    {
        id: 2,
        photo: director_3,
        name: 'Иванова Светлана Юрьевна',
        jobTitle: 'И.О. Губернатора области',
        nationality: 'Русская',
        education: 'высшее',
        specialty: 'юрист',
        academicDegree: 'нет'
    },
    {
        id: 3,
        photo: director_2,
        name: 'Орлов Сергей Сергеевич',
        jobTitle: 'Губернатор области',
        nationality: 'Русский',
        education: 'высшее',
        specialty: 'юрист',
        academicDegree: 'доктор наук'
    },
    {
        id: 4,
        photo: director_4,
        name: 'Аркадов Дмитрий Иванович',
        jobTitle: 'И.О Губернатора области',
        nationality: 'Русский',
        education: 'высшее',
        specialty: 'юрист',
        academicDegree: 'бакалавр'
    },

];

export const workActivity = [
    {
        dateFrom: 'С 29 марта 2023',
        dateTo: 'по Н.В.',
        text: 'временно исполняющего обязанности губернатора Омской области'
    },
    {
        dateFrom: 'С 8 июня 2022',
        dateTo: 'по 29 марта 2023',
        text: 'заместитель министра спорта Кабардино-Балкарской Республики, г.Нальчик'
    },
    {
        dateFrom: 'С 12 сентября 2019',
        dateTo: 'по 8 июня 2022',
        text: 'заместитель министра спорта и туризма Кабардино-Балкарской Республики, г.Нальчик'
    },
    {
        dateFrom: 'С 12 августа 2017',
        dateTo: 'по 8 сентября 2018',
        text: 'Директор Департамента промышленной политики и проектного управления Министерства промышленности и торговли РФ'
    },
];
