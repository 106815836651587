import React from 'react';
import styled from 'styled-components';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {formatNumber, groupByName} from '../../helpers';
import {Text} from '../../fields';
import {RegionInfoCollapse} from './RegionInfoCollapse';
import {setSelectedGroupIndex} from '../../screens/home/store/home.slice';

export const RegionInfo = () => {
    const dispatch = useAppDispatch();
    const {region, selectedGroupIndex} = useAppSelector(state => state.home);

    const handleSelectGroup = (index: number) => {
        dispatch(setSelectedGroupIndex(selectedGroupIndex === index ? null : index));
    };

    const group = groupByName(region?.data ?? [], true);

    if (group.length === 0) {
        return (
            <DefaultBlock>
                <Text
                    as={'span'}
                    size={18}
                    weight={400}
                    color={'var(--color-text-light)'}
                >
                    Выберите<br/>показатель
                </Text>
            </DefaultBlock>
        );
    }

    const names = groupByName(region?.data ?? [], true)
        .map((i, idx) =>
            (
                {
                    name: i.name,
                    info: formatNumber(i.total.toFixed()),
                    indexItem: idx
                }));

    return (
        <List>
            <Item>
                <Text
                    as={'span'}
                    size={18}
                    weight={400}
                    color={'var(--color-main)'}
                >
                    Всего
                </Text>
                <Text
                    as={'span'}
                    size={18}
                    weight={400}
                    color={'var(--color-text)'}
                >
                    {formatNumber(region.totalValues.toFixed())}
                </Text>
            </Item>
            {group.map((item, index) => {
                const total = item.items.map(i => i.Value).reduce((acc, i) => acc + i, 0);
                return (
                    <RegionInfoCollapse
                        key={index}
                        name={item.name}
                        total={total}
                        index={index}
                        names={names}
                        items={item.items}
                        ObjGeoID={item.ObjGeoID}
                        isOpen={selectedGroupIndex === index}
                        setIsOpen={() => handleSelectGroup(index)}
                    />
                );
            })}
        </List>
    );
};

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  transition: transform 0.1s ease, box-shadow 0.1s ease;
  transform: translateY(0);

  &:active {
    transform: translateY(2px);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1600px) {
    padding: 8px;
  }
`;

const DefaultBlock = styled.div`
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 20px;
`;
