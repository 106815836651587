import {combineReducers} from '@reduxjs/toolkit';
import {api} from './api';
import authSlice from '../screens/ayth/store/auth.slice';
import homeSlice from '../screens/home/store/home.slice';


export const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth: authSlice,
    home: homeSlice,
});
