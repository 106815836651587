import React, {useLayoutEffect, useRef} from 'react';
import {COLOR_MAP, COLOR_MAP_ACTIVE, COLOR_MAP_HOVER} from '../utils/constants';
import {darkenColor, groupByYears} from '../helpers';
import {useAppSelector} from './app';
import {regions} from '../components/map/Map';

type SvgInteractiveHook = {
    svgRef: React.RefObject<SVGSVGElement>;
};

export type UseSvgHookOnClick = { regionId?: string, name?: string }

interface UseSvgProps {
    hover: (hoverRegionName: string) => void;
    click: ({regionId, name}: UseSvgHookOnClick) => void;
}

export const useSvgInteractive = (
    {
        click,
        hover
    }: UseSvgProps): SvgInteractiveHook => {
    const {regionId, region, yearIndex} = useAppSelector(state => state.home);
    const svgRef = useRef<SVGSVGElement>(null);

    const viewContent = regions[regionId?.replace('relation/', '')]?.names as any ?? [];
    // const colorData = regions[regionId?.replace('relation/', '')]?.names as RegionData[] ?? [];

    const group = groupByYears(region.data, true);

    useLayoutEffect(() => {
        const svgElement = svgRef.current;
        // Проверяем, что svg элемент существует и доступен
        if (svgElement) {
            group[yearIndex]?.items?.filter(i => i).forEach(({Color, ObjGeoID}) => {
                const pathElement = svgElement.querySelector(`path#${CSS.escape(ObjGeoID)}`);
                if (pathElement) {
                    pathElement.setAttribute('fill', Color);
                    pathElement.setAttribute('data-original-color', Color);
                }
            });
        }
    }, [group, region, regionId]);

    useLayoutEffect(() => {
        const svgElement = svgRef.current;

        // Если элемент SVG уже существует, привязываем обработчики
        if (svgElement) {
            const handleMouseEnter = (event: Event) => {
                const target = event.target as SVGPathElement;
                const currentColor = target.getAttribute('fill');
                if (currentColor) {
                    setTimeout(() => {
                        const darkerColor = darkenColor(currentColor as string, 50);
                        target.setAttribute('fill', darkerColor);
                    }, 0);
                } else {
                    target.setAttribute('fill', COLOR_MAP_HOVER);
                }

                const regionId = target.id;
                if (regionId) {
                    const region = viewContent?.find((i: any) => i.id === regionId);
                    if (region) {
                        hover(region.name);
                    } else {
                        hover('');
                    }
                }
            };

            const handleMouseLeave = (event: Event) => {
                const target = event.target as SVGPathElement;
                const regionId = target.id;
                if (regionId) {
                    const originalColor = target.getAttribute('data-original-color');
                    if (originalColor) {
                        target.setAttribute('fill', originalColor);
                    } else {
                        target.setAttribute('fill', COLOR_MAP);
                    }
                }
            };

            const handleClick = (event: Event) => {
                const target = event.target as SVGPathElement;
                const regionId = target.id;

                if (regionId) {
                    target.setAttribute('fill', COLOR_MAP_ACTIVE);
                }
                const region = viewContent?.find((i: any) => i.id === regionId);

                if (region) {
                    click({regionId, name: region.name || ''});
                } else {
                    event.preventDefault();
                }
            };

            const handleSvgMouseLeave = () => {
                hover('');
            };

            // Привязываем события только если элемент доступен
            svgElement.addEventListener('mouseenter', handleMouseEnter, true);
            svgElement.addEventListener('mouseleave', handleMouseLeave, true);
            svgElement.addEventListener('click', handleClick, true);
            svgElement.addEventListener('mouseleave', handleSvgMouseLeave);

            return () => {
                svgElement.removeEventListener('mouseenter', handleMouseEnter, true);
                svgElement.removeEventListener('mouseleave', handleMouseLeave, true);
                svgElement.removeEventListener('click', handleClick, true);
                svgElement.removeEventListener('mouseleave', handleSvgMouseLeave);
            };
        }
    }, [viewContent, regionId]); // зависимость от данных и regionId

    return {svgRef};
};
