import React from 'react';
import styled from 'styled-components';
import {Text} from '../../fields';

export const AppLoader = () => {
    return (
        <Wrapper>
            <Text
                as={'p'}
                size={16}
                weight={600}
                color={'var(--color-main)'}
            >загрузка...</Text>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;
