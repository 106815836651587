import React, {useEffect, useState} from 'react';
import {Text} from '../../../fields';
import styled from 'styled-components';
import {RisingType} from '../config';

export const LeaderReport = (
    {
        text,
        positiveValue,
        negativeValue
    }: RisingType) => {
    const [displayPositiveValue, setDisplayValue] = useState(0);
    const [displayNegativeValue, setDisplayNegativeValue] = useState(0);

    useEffect(() => {
        let currentValue = 0;
        const increment = Math.ceil(positiveValue / 100); // Скорость увеличения числа
        const interval = setInterval(() => {
            currentValue += increment;
            if (currentValue >= positiveValue) {
                currentValue = positiveValue;
                clearInterval(interval);
            }
            setDisplayValue(currentValue);
        }, 15);
        return () => clearInterval(interval);
    }, [positiveValue]);

    useEffect(() => {
        let currentValue = 0;
        const increment = Math.ceil(negativeValue / 100); // Скорость увеличения числа
        const interval = setInterval(() => {
            currentValue += increment;
            if (currentValue >= negativeValue) {
                currentValue = negativeValue;
                clearInterval(interval);
            }
            setDisplayNegativeValue(currentValue);
        }, 15);
        return () => clearInterval(interval);
    }, [negativeValue]);

    return (
        <Content>
            <Text
                as={'p'}
                color={'var(--color-text)'}
                weight={500}
                size={18}
            >{text}</Text>
            <Body>
                <RisingRow>
                    <ColumnRising
                        style={{width: `${displayPositiveValue}%`}}
                        color={'var(--color-success)'}>
                        <Value>{displayPositiveValue}%</Value>
                    </ColumnRising>
                </RisingRow>
                <RisingRow>
                    <ColumnRising
                        style={{width: `${displayNegativeValue}%`}}
                        color={'var(--color-error)'}>
                        <Value>{displayNegativeValue}%</Value>
                    </ColumnRising>
                </RisingRow>
            </Body>
        </Content>
    );
};


const Content = styled.div`
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const RisingRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ColumnRising = styled.div.withConfig({
    shouldForwardProp: prop => !['color'].includes(prop)
})<{ color: string }>`
  height: 50px;
  background-color: ${({color}) => color};
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Value = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font);
  color: var(--color-text);
  //text-shadow: 2px 2px 0 white,
  //-2px 2px 0 white,
  //  2px -2px 0 white,
  //  -2px -2px 0 white;
`;
