import React, {Suspense, useMemo} from 'react';
import * as AL from './regionNames/RU-AL_145194';
import * as AMU from './regionNames/RU-AMU_147166';
import * as BA from './regionNames/RU-BA_77677';
import * as BU from './regionNames/RU-BU_145729';
import * as CE from './regionNames/RU-CE_109877';
import * as CHE from './regionNames/RU-CHE_77687';
import * as CHU from './regionNames/RU-CHU_151231';
import * as CU from './regionNames/RU-CU_80513';
import * as DA from './regionNames/RU-DA_109876';
import * as IN from './regionNames/RU-IN_253252';
import * as IRK from './regionNames/RU-IRK_145454';
import * as IVA from './regionNames/RU-IVA_85617';
import * as KAM from './regionNames/RU-KAM_151233';
import * as KB from './regionNames/RU-KB_109879';
import * as KC from './regionNames/RU-KC_109878';
import * as KDA from './regionNames/RU-KDA_108082';
import * as KEM from './regionNames/RU-KEM_144763';
import * as KGD from './regionNames/RU-KGD_103906';
import * as KGN from './regionNames/RU-KGN_140290';
import * as KHA from './regionNames/RU-KHA_151223';
import * as KHM from './regionNames/RU-KHM_140296';
import * as KIR from './regionNames/RU-KIR_115100';
import * as KK from './regionNames/RU-KK_190911';
import * as KL from './regionNames/RU-KL_108083';
import * as KOS from './regionNames/RU-KOS_85963';
import * as KO from './regionNames/RU-KO_115136';
import * as KRS from './regionNames/RU-KRS_72223';
import * as KR from './regionNames/RU-KR_393980';
import * as KYA from './regionNames/RU-KYA_190090';
import * as LEN from './regionNames/RU-LEN_176095';
import * as LIP from './regionNames/RU-LIP_72169';
import * as MAG from './regionNames/RU-MAG_151228';
import * as ME from './regionNames/RU-ME_115114';
import * as MOS from './regionNames/RU-MOS_51490';
import * as MOW from './regionNames/RU-MOW_102269';
import * as MO from './regionNames/RU-MO_72196';
import * as MUR from './regionNames/RU-MUR_2099216';
import * as NEN from './regionNames/RU-NEN_274048';
import * as NGR from './regionNames/RU-NGR_89331';
import * as NIZ from './regionNames/RU-NIZ_72195';
import * as NVS from './regionNames/RU-NVS_140294';
import * as OMS from './regionNames/RU-OMS_140292';
import * as ORE from './regionNames/RU-ORE_77669';
import * as ORL from './regionNames/RU-ORL_72224';
import * as PNZ from './regionNames/RU-PNZ_72182';
import * as PRI from './regionNames/RU-PRI_151225';
import * as PSK from './regionNames/RU-PSK_155262';
import * as ROS from './regionNames/RU-ROS_85606';
import * as RYA from './regionNames/RU-RYA_71950';
import * as SAK from './regionNames/RU-SAK_394235';
import * as SAM from './regionNames/RU-SAM_72194';
import * as SAR from './regionNames/RU-SAR_72193';
import * as SA from './regionNames/RU-SA_151234';
import * as SE from './regionNames/RU-SE_110032';
import * as SMO from './regionNames/RU-SMO_81996';
import * as SPE from './regionNames/RU-SPE_337422';
import * as STA from './regionNames/RU-STA_108081';
import * as SVE from './regionNames/RU-SVE_79379';
import * as TAM from './regionNames/RU-TAM_72180';
import * as TA from './regionNames/RU-TA_79374';
import * as TOM from './regionNames/RU-TOM_140295';
import * as TUL from './regionNames/RU-TUL_81993';
import * as TVE from './regionNames/RU-TVE_2095259';
import * as TYU from './regionNames/RU-TYU_140291';
import * as TY from './regionNames/RU-TY_145195';
import * as UD from './regionNames/RU-UD_115134';
import * as ULY from './regionNames/RU-ULY_72192';
import * as VGG from './regionNames/RU-VGG_77665';
import * as VLA from './regionNames/RU-VLA_72197';
import * as VLG from './regionNames/RU-VLG_115106';
import * as VOR from './regionNames/RU-VOR_72181';
import * as YAN from './regionNames/RU-YAN_191706';
import * as YAR from './regionNames/RU-YAR_81994';
import * as YEV from './regionNames/RU-YEV_147167';
import * as DON from './regionNames/RU_DON_71980';
import * as ZAP from './regionNames/RU_ZAP_71973';
import * as HERSON from './regionNames/RU_HERSON_71971';
import * as LUG from './regionNames/RU_LUG_71022';
import * as RUS from './regionNames/RU';

import styled from 'styled-components';
import {UseSvgHookOnClick} from '../../hooks/useSvgInteractive';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {Text} from '../../fields';
import {changeHoverRegionName, changeRegionId, changeSubjectName} from '../../screens/home/store/home.slice';
import {AppLoader} from '../appLoader';
import {mediaWidth} from '../../utils/mixins';
import {NavLink} from 'react-router-dom';

const RuMos = React.lazy(() => import('./mapLayouts/RuMos'));
const RuRya = React.lazy(() => import('./mapLayouts/RuRya'));
const RuLip = React.lazy(() => import('./mapLayouts/RuLip'));
const RuTam = React.lazy(() => import('./mapLayouts/RuTam'));
const RuVor = React.lazy(() => import('./mapLayouts/RuVor'));
const RuPnz = React.lazy(() => import('./mapLayouts/RuPnz'));
const RuUly = React.lazy(() => import('./mapLayouts/RuUly'));
const RuSar = React.lazy(() => import('./mapLayouts/RuSar'));
const RuSam = React.lazy(() => import('./mapLayouts/RuSam'));
const RuNiz = React.lazy(() => import('./mapLayouts/RuNiz'));
const RuMo = React.lazy(() => import('./mapLayouts/RuMo'));
const RuVla = React.lazy(() => import('./mapLayouts/RuVla'));
const RuKrs = React.lazy(() => import('./mapLayouts/RuKrs'));
const RuOrl = React.lazy(() => import('./mapLayouts/RuOrl'));
const RuVgg = React.lazy(() => import('./mapLayouts/RuVgg'));
const RuOre = React.lazy(() => import('./mapLayouts/RuOre'));
const RuBa = React.lazy(() => import('./mapLayouts/RuBa'));
const RuChe = React.lazy(() => import('./mapLayouts/RuChe'));
const RuTa = React.lazy(() => import('./mapLayouts/RuTa'));
const RuSve = React.lazy(() => import('./mapLayouts/RuSve'));
const RuCu = React.lazy(() => import('./mapLayouts/RuCu'));
const RuTul = React.lazy(() => import('./mapLayouts/RuTul'));
const RuYar = React.lazy(() => import('./mapLayouts/RuYar'));
const RuSmo = React.lazy(() => import('./mapLayouts/RuSmo'));
const RuRos = React.lazy(() => import('./mapLayouts/RuRos'));
const RuKos = React.lazy(() => import('./mapLayouts/RuKos'));
const RuNgr = React.lazy(() => import('./mapLayouts/RuNgr'));
const RuMow = React.lazy(() => import('./mapLayouts/RuMow'));
const RuKgd = React.lazy(() => import('./mapLayouts/RuKgd'));
const RuIva = React.lazy(() => import('./mapLayouts/RuIva'));
const RuSta = React.lazy(() => import('./mapLayouts/RuSta'));
const RuKda = React.lazy(() => import('./mapLayouts/RuKda'));
const RuKl = React.lazy(() => import('./mapLayouts/RuKl'));
const RuDa = React.lazy(() => import('./mapLayouts/RuDa'));
const RuCe = React.lazy(() => import('./mapLayouts/RuCe'));
const RuKc = React.lazy(() => import('./mapLayouts/RuKc'));
const RuSe = React.lazy(() => import('./mapLayouts/RuSe'));
const RuKir = React.lazy(() => import('./mapLayouts/RuKir'));
const RuVlg = React.lazy(() => import('./mapLayouts/RuVlg'));
const RuMe = React.lazy(() => import('./mapLayouts/RuMe'));
const RuUd = React.lazy(() => import('./mapLayouts/RuUd'));
const RuKo = React.lazy(() => import('./mapLayouts/RuKo'));
const RuKgn = React.lazy(() => import('./mapLayouts/RuKgn'));
const RuTyu = React.lazy(() => import('./mapLayouts/RuTyu'));
const RuNvs = React.lazy(() => import('./mapLayouts/RuNvs'));
const RuTom = React.lazy(() => import('./mapLayouts/RuTom'));
const RuKhm = React.lazy(() => import('./mapLayouts/RuKhm'));
const RuKem = React.lazy(() => import('./mapLayouts/RuKem'));
const RuAl = React.lazy(() => import('./mapLayouts/RuAl'));
const RuTy = React.lazy(() => import('./mapLayouts/RuTy'));
const RuIrk = React.lazy(() => import('./mapLayouts/RuIrk'));
const RuBu = React.lazy(() => import('./mapLayouts/RuBu'));
const RuAmu = React.lazy(() => import('./mapLayouts/RuAmu'));
const RuYev = React.lazy(() => import('./mapLayouts/RuYev'));
const RuKha = React.lazy(() => import('./mapLayouts/RuKha'));
const RuPri = React.lazy(() => import('./mapLayouts/RuPri'));
const RuMag = React.lazy(() => import('./mapLayouts/RuMag'));
const RuChu = React.lazy(() => import('./mapLayouts/RuChu'));
const RuKam = React.lazy(() => import('./mapLayouts/RuKam'));
const RuSa = React.lazy(() => import('./mapLayouts/RuSa'));
const RuPsk = React.lazy(() => import('./mapLayouts/RuPsk'));
const RuLen = React.lazy(() => import('./mapLayouts/RuLen'));
const RuKya = React.lazy(() => import('./mapLayouts/RuKya'));
const RuKk = React.lazy(() => import('./mapLayouts/RuKk'));
const RuYan = React.lazy(() => import('./mapLayouts/RuYan'));
const RuIn = React.lazy(() => import('./mapLayouts/RuIn'));
const RuNen = React.lazy(() => import('./mapLayouts/RuNen'));
const RuSpe = React.lazy(() => import('./mapLayouts/RuSpe'));
const RuKr = React.lazy(() => import('./mapLayouts/RuKr'));
const RuSak = React.lazy(() => import('./mapLayouts/RuSak'));
const RuTve = React.lazy(() => import('./mapLayouts/RuTve'));
const RuMur = React.lazy(() => import('./mapLayouts/RuMur'));
const RuKb = React.lazy(() => import('./mapLayouts/RuKb'));
const RU_ZAP = React.lazy(() => import('./mapLayouts/RU_ZAP'));
const RU_DON = React.lazy(() => import('./mapLayouts/RU_DON'));
const RU_LUG = React.lazy(() => import('./mapLayouts/RU_LUG'));
const RU_HERSON = React.lazy(() => import('./mapLayouts/RU_HERSON'));
const RuOms = React.lazy(() => import('./mapLayouts/RuOms'));
const RU = React.lazy(() => import('./mapLayouts/RU'));


export const regions: any = {
    rus: RUS,
    145194: AL,
    147166: AMU,
    77677: BA,
    145729: BU,
    109877: CE,
    77687: CHE,
    151231: CHU,
    80513: CU,
    109876: DA,
    253252: IN,
    145454: IRK,
    85617: IVA,
    151233: KAM,
    109879: KB,
    109878: KC,
    108082: KDA,
    144763: KEM,
    103906: KGD,
    140290: KGN,
    151223: KHA,
    140296: KHM,
    115100: KIR,
    190911: KK,
    108083: KL,
    85963: KOS,
    115136: KO,
    72223: KRS,
    393980: KR,
    190090: KYA,
    176095: LEN,
    72169: LIP,
    151228: MAG,
    115114: ME,
    51490: MOS,
    102269: MOW,
    72196: MO,
    2099216: MUR,
    274048: NEN,
    89331: NGR,
    72195: NIZ,
    140294: NVS,
    140292: OMS,
    77669: ORE,
    72224: ORL,
    72182: PNZ,
    151225: PRI,
    155262: PSK,
    85606: ROS,
    71950: RYA,
    394235: SAK,
    72194: SAM,
    72193: SAR,
    151234: SA,
    110032: SE,
    81996: SMO,
    337422: SPE,
    108081: STA,
    79379: SVE,
    72180: TAM,
    79374: TA,
    140295: TOM,
    81993: TUL,
    2095259: TVE,
    140291: TYU,
    145195: TY,
    115134: UD,
    72192: ULY,
    77665: VGG,
    72197: VLA,
    115106: VLG,
    72181: VOR,
    191706: YAN,
    81994: YAR,
    147167: YEV,
    71973: DON,
    71980: ZAP,
    71022: HERSON,
    71971: LUG,
};

type RenderMapProps = {
    onlyRu: boolean;
}

export const colorsMap = [
    {level: 'Плохо', color: '#DF5A5A'},
    {level: 'Не удовлетворительно', color: '#EA9958'},
    {level: 'Удовлетворительно', color: '#DEC14C'},
    {level: 'Хорошо', color: '#97BD4B'},
    {level: 'Отлично', color: '#4DA34D'},
];

const Map = ({onlyRu}: RenderMapProps) => {
    const dispatch = useAppDispatch();
    const {regionId, selectSubjectName} = useAppSelector(state => state.home);

    const onHover = (hoverRegionName: string) => {
        dispatch(changeHoverRegionName({hoverRegionName}));
    };

    const onClick = ({regionId, name}: UseSvgHookOnClick) => {
        regionId && dispatch(changeRegionId({regionId}));
        name && dispatch(changeSubjectName({selectSubjectName: name}));
    };

    const renderSvg = useMemo(() => {
        if (onlyRu) {
            return <RU onClick={onClick} onHover={onHover}/>;
        }

        switch (regionId) {
            case 'relation/51490':
                return <RuMos onClick={onClick} onHover={onHover}/>;
            case 'relation/71950':
                return <RuRya onClick={onClick} onHover={onHover}/>;
            case 'relation/72169':
                return <RuLip onClick={onClick} onHover={onHover}/>;
            case 'relation/72180':
                return <RuTam onClick={onClick} onHover={onHover}/>;
            case 'relation/72181':
                return <RuVor onClick={onClick} onHover={onHover}/>;
            case 'relation/72182':
                return <RuPnz onClick={onClick} onHover={onHover}/>;
            case 'relation/72192':
                return <RuUly onClick={onClick} onHover={onHover}/>;
            case 'relation/72193':
                return <RuSar onClick={onClick} onHover={onHover}/>;
            case 'relation/72194':
                return <RuSam onClick={onClick} onHover={onHover}/>;
            case 'relation/72195':
                return <RuNiz onClick={onClick} onHover={onHover}/>;
            case 'relation/72196':
                return <RuMo onClick={onClick} onHover={onHover}/>;
            case 'relation/72197':
                return <RuVla onClick={onClick} onHover={onHover}/>;
            case 'relation/72223':
                return <RuKrs onClick={onClick} onHover={onHover}/>;
            case 'relation/72224':
                return <RuOrl onClick={onClick} onHover={onHover}/>;
            // case 'relation/72639':
            //     return <Ua-43 onClick={onClick} onHover={onHover}/>;
            case 'relation/77665':
                return <RuVgg onClick={onClick} onHover={onHover}/>;
            case 'relation/77669':
                return <RuOre onClick={onClick} onHover={onHover}/>;
            case 'relation/77677':
                return <RuBa onClick={onClick} onHover={onHover}/>;
            case 'relation/77687':
                return <RuChe onClick={onClick} onHover={onHover}/>;
            case 'relation/79374':
                return <RuTa onClick={onClick} onHover={onHover}/>;
            case 'relation/79379':
                return <RuSve onClick={onClick} onHover={onHover}/>;
            case 'relation/80513':
                return <RuCu onClick={onClick} onHover={onHover}/>;
            case 'relation/81993':
                return <RuTul onClick={onClick} onHover={onHover}/>;
            case 'relation/81994':
                return <RuYar onClick={onClick} onHover={onHover}/>;
            // case 'relation/81995':
            //     return <RuKlu onClick={onClick} onHover={onHover}/>;
            case 'relation/81996':
                return <RuSmo onClick={onClick} onHover={onHover}/>;
            // case 'relation/81997':
            //     return <RuBry onClick={onClick} onHover={onHover}/>;
            // case 'relation/83184':
            //     return <RuBel onClick={onClick} onHover={onHover}/>;
            case 'relation/85606':
                return <RuRos onClick={onClick} onHover={onHover}/>;
            case 'relation/85617':
                return <RuIva onClick={onClick} onHover={onHover}/>;
            case 'relation/85963':
                return <RuKos onClick={onClick} onHover={onHover}/>;
            case 'relation/89331':
                return <RuNgr onClick={onClick} onHover={onHover}/>;
            case 'relation/102269':
                return <RuMow onClick={onClick} onHover={onHover}/>;
            case 'relation/103906':
                return <RuKgd onClick={onClick} onHover={onHover}/>;
            case 'relation/108081':
                return <RuSta onClick={onClick} onHover={onHover}/>;
            case 'relation/108082':
                return <RuKda onClick={onClick} onHover={onHover}/>;
            case 'relation/108083':
                return <RuKl onClick={onClick} onHover={onHover}/>;
            case 'relation/109876':
                return <RuDa onClick={onClick} onHover={onHover}/>;
            case 'relation/109877':
                return <RuCe onClick={onClick} onHover={onHover}/>;
            case 'relation/109878':
                return <RuKc onClick={onClick} onHover={onHover}/>;
            case 'relation/109879':
                return <RuKb onClick={onClick} onHover={onHover}/>;
            case 'relation/110032':
                return <RuSe onClick={onClick} onHover={onHover}/>;
            // case 'relation/112819':
            //     return <RuAst onClick={onClick} onHover={onHover}/>;
            case 'relation/115100':
                return <RuKir onClick={onClick} onHover={onHover}/>;
            case 'relation/115106':
                return <RuVlg onClick={onClick} onHover={onHover}/>;
            case 'relation/115114':
                return <RuMe onClick={onClick} onHover={onHover}/>;
            case 'relation/115134':
                return <RuUd onClick={onClick} onHover={onHover}/>;
            // case 'relation/115135':
            //     return <RuPer onClick={onClick} onHover={onHover}/>;
            case 'relation/115136':
                return <RuKo onClick={onClick} onHover={onHover}/>;
            case 'relation/140290':
                return <RuKgn onClick={onClick} onHover={onHover}/>;
            case 'relation/140291':
                return <RuTyu onClick={onClick} onHover={onHover}/>;
            case 'relation/140292':
                return <RuOms onClick={onClick} onHover={onHover}/>;
            case 'relation/140294':
                return <RuNvs onClick={onClick} onHover={onHover}/>;
            case 'relation/140295':
                return <RuTom onClick={onClick} onHover={onHover}/>;
            case 'relation/140296':
                return <RuKhm onClick={onClick} onHover={onHover}/>;
            // case 'relation/140337':
            //     return <RuArk onClick={onClick} onHover={onHover}/>;
            case 'relation/144763':
                return <RuKem onClick={onClick} onHover={onHover}/>;
            // case 'relation/144764':
            //     return <RuAlt onClick={onClick} onHover={onHover}/>;
            case 'relation/145194':
                return <RuAl onClick={onClick} onHover={onHover}/>;
            case 'relation/145195':
                return <RuTy onClick={onClick} onHover={onHover}/>;
            case 'relation/145454':
                return <RuIrk onClick={onClick} onHover={onHover}/>;
            case 'relation/145729':
                return <RuBu onClick={onClick} onHover={onHover}/>;
            // case 'relation/145730':
            //     return <RuZab onClick={onClick} onHover={onHover}/>;
            case 'relation/147166':
                return <RuAmu onClick={onClick} onHover={onHover}/>;
            case 'relation/147167':
                return <RuYev onClick={onClick} onHover={onHover}/>;
            case 'relation/151223':
                return <RuKha onClick={onClick} onHover={onHover}/>;
            case 'relation/151225':
                return <RuPri onClick={onClick} onHover={onHover}/>;
            case 'relation/151228':
                return <RuMag onClick={onClick} onHover={onHover}/>;
            case 'relation/151231':
                return <RuChu onClick={onClick} onHover={onHover}/>;
            case 'relation/151233':
                return <RuKam onClick={onClick} onHover={onHover}/>;
            case 'relation/151234':
                return <RuSa onClick={onClick} onHover={onHover}/>;
            case 'relation/155262':
                return <RuPsk onClick={onClick} onHover={onHover}/>;
            case 'relation/176095':
                return <RuLen onClick={onClick} onHover={onHover}/>;
            case 'relation/190090':
                return <RuKya onClick={onClick} onHover={onHover}/>;
            case 'relation/190911':
                return <RuKk onClick={onClick} onHover={onHover}/>;
            case 'relation/191706':
                return <RuYan onClick={onClick} onHover={onHover}/>;
            case 'relation/253252':
                return <RuIn onClick={onClick} onHover={onHover}/>;
            // case 'relation/253256':
            //     return <RuAd onClick={onClick} onHover={onHover}/>;
            case 'relation/274048':
                return <RuNen onClick={onClick} onHover={onHover}/>;
            case 'relation/337422':
                return <RuSpe onClick={onClick} onHover={onHover}/>;
            case 'relation/393980':
                return <RuKr onClick={onClick} onHover={onHover}/>;
            case 'relation/394235':
                return <RuSak onClick={onClick} onHover={onHover}/>;
            case 'relation/2095259':
                return <RuTve onClick={onClick} onHover={onHover}/>;
            case 'relation/2099216':
                return <RuMur onClick={onClick} onHover={onHover}/>;
            case 'relation/71022':
                return <RU_HERSON onClick={onClick} onHover={onHover}/>;
            case 'relation/71980':
                return <RU_ZAP onClick={onClick} onHover={onHover}/>;
            case 'relation/71973':
                return <RU_DON onClick={onClick} onHover={onHover}/>;
            case 'relation/71971':
                return <RU_LUG onClick={onClick} onHover={onHover}/>;
            default:
                return <RU onClick={onClick} onHover={onHover}/>;
        }
    }, [regionId]);
    const id = regionId.replace('relation/', '');

    return (
        <Suspense fallback={<AppLoader/>}>
            <Wrapper>
                {selectSubjectName !== ''
                    ? <Link to={`/region/${id}`} size={12}>{selectSubjectName}</Link>
                    : null}
                <Legend>

                    {colorsMap.map(item => (
                        <LegendItem key={item.level}>
                            <LegendBar color={item.color}/>
                            <Text
                                as={'p'}
                                size={14}
                                weight={500}
                                color={'var(--color-text)'}
                            >{item.level}</Text>
                        </LegendItem>
                    ))}
                </Legend>
                <MapWrapper>
                    {renderSvg}
                </MapWrapper>

            </Wrapper>
        </Suspense>
    );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  & svg {
    height: 443px;
    ${() => mediaWidth(800)}
  }
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 3px;
  position: absolute;
  left: 0;
  bottom: 0;
`;

const Link = styled(NavLink).withConfig({
    shouldForwardProp: prop => !['size'].includes(prop)
})<{ size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  border-radius: 8px;
  padding: 12px;
  height: 40px;
  font-family: var(--font);
  font-size: ${({size}) => size}px;
  font-weight: 400;
  color: var(--color-text);
  margin: 0;

  text-decoration: none;

  transition: transform 0.1s ease, box-shadow 0.1s ease;
  transform: translateY(0);

  &:active {
    transform: translateY(2px);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  }

  @media (max-width: 1600px) {
    font-size: ${({size}) => size * 0.7}px; /* 80% от исходного размера */
    line-height: ${({size}) => size * 0.7}px; /* 80% от исходного размера */
  }
  @media (max-width: 1440px) {
    font-size: ${({size}) => size * 0.6}px; /* 70% от исходного размера */
    line-height: ${({size}) => size * 0.6}px; /* 70% от исходного размера */
  }
  @media (max-width: 1200px) {
    font-size: ${({size}) => size * 0.5}px; /* 60% от исходного размера */
    line-height: ${({size}) => size * 0.5}px; /* 60% от исходного размера */
  }
  @media (max-width: 1024px) {
    font-size: ${({size}) => size * 0.4}px; /* 50% от исходного размера */
    line-height: ${({size}) => size * 0.4}px; /* 50% от исходного размера */
  }
  @media (max-width: 991px) {
    font-size: ${({size}) => size * 0.3}px; /* 40% от исходного размера */
    line-height: ${({size}) => size * 0.3}px; /* 40% от исходного размера */
  }
`;

const LegendItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;

  &:nth-child(5) span {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:nth-child(1) span {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

const LegendBar = styled.span.withConfig({
    shouldForwardProp: prop => !['color'].includes(prop)
})<{ color: string }>`
  width: 9px;
  height: 30px;
  background-color: ${({color}) => color};
`;

const MapWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`;


export default Map;
