import React from 'react';
import styled from 'styled-components';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {Text} from '../../fields';
import {formatNumber, groupByYears} from '../../helpers';
import {DifferenceInfo} from '../diference';
import {setYearIndex} from '../../screens/home/store/home.slice';
import {Tooltip} from '../tooltip';

export type YearCard = {
    name: string;
    info: string;
    indexItem: number | string;
}

const defaultCard = Array.from({length: 8}, (_, i) => i + 1);

export const YearCards = () => {
    const dispatch = useAppDispatch();
    const {region, yearIndex} = useAppSelector(state => state.home);

    const years = groupByYears(region.data, false)
        .map((i, idx) =>
            ({name: i.year, info: formatNumber(i.total.toFixed()), indexItem: idx})) as YearCard[];

    const switchYear = (index: number) => {
        dispatch(setYearIndex(index));
    };

    return (
        <>
            <Cards>
                {years.length > 0 ? years.map((item, idx) => (

                        <Card
                            key={item.name}
                            active={yearIndex === idx}
                            onClick={() => switchYear(idx)}
                        >
                            <Tooltip
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between'
                                }}
                                tooltipText={`динамика за весь ${item.name}`}>
                                <CardHead>
                                    <Text
                                        as={'span'}
                                        size={16}
                                        weight={500}
                                        color={'var(--color-text)'}
                                    >
                                        {item.name}
                                    </Text>
                                    <DifferenceInfo info={item.info} fontSize={10} options={years} index={idx}/>
                                </CardHead>
                                <Text
                                    as={'span'}
                                    size={24}
                                    weight={500}
                                    color={'var(--color-text)'}
                                >{item.info}</Text>
                            </Tooltip>
                        </Card>

                    ))
                    : defaultCard.map((item, idx) => (
                        <Card
                            key={item}
                            active={false}
                        >
                            <Text
                                as={'span'}
                                size={14}
                                weight={500}
                                color={'var(--color-text-light)'}
                            >Выберите<br/>показатель</Text>
                        </Card>))
                }
            </Cards>
        </>
    );
};

const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  padding-bottom: 20px;
  position: relative;

  @media (max-width: 1280px) {
    flex-wrap: wrap;
    row-gap: 10px;
  }
`;

const Card = styled.div.withConfig({
    shouldForwardProp: prop => !['active'].includes(prop)
})<{ active: boolean }>`
  background-color: ${({active}) => active ? 'var(--background-active)' : 'var(--background)'};
  border: 1px solid ${({active}) => active ? 'var(--color-main)' : 'transparent'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: var(--box-shadow);
  flex-grow: 1;
  padding: 12px;
  border-radius: 12px;
  height: 81px;
  width: 12%;


  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1280px) {
    width: 20%;
    height: 61px;
  }

`;

const CardHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
