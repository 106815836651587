import {api} from '../../../store/api';
import {SignForm} from './types';

export const authApi = api.injectEndpoints({
    endpoints: build => ({
        signIn: build.mutation<{ user: any, status: boolean }, SignForm>({
            query: ({login, pwd}) => ({
                url: '/authorization/login',
                method: 'POST',
                body: {login, pwd}
            })
        })
    }),
    overrideExisting: true
});

export const {useSignInMutation} = authApi;
