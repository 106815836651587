import {useEffect, useRef, useState} from 'react';

interface Position {
    x: number;
    y: number;
}

export const useTooltip = () => {
    const [tooltip, setTooltip] = useState<string | null>(null);
    const [position, setPosition] = useState<Position>({x: 0, y: 0});

    const tooltipRef = useRef<HTMLDivElement | null>(null);

    const showTooltip = (e: MouseEvent) => {
        const target = e.target as HTMLDivElement;
        if (target && target.hasAttribute('data-attr-tooltip')) {
            const tooltipText = target.getAttribute('data-attr-tooltip');
            setTooltip(tooltipText);
            const rect = target.getBoundingClientRect();
            setPosition({
                x: rect.left + rect.width / 2,  // Центрируем tooltip относительно элемента
                y: rect.top + window.scrollY + rect.height + 5,  // Располагаем tooltip ниже элемента
            });
        }
    };

    const hideTooltip = () => {
        setTooltip(null); // Скрываем tooltip
    };

    useEffect(() => {
        const element = tooltipRef.current;
        if (element) {
            // Навешиваем обработчики событий для показа и скрытия tooltip
            element.addEventListener('mouseenter', showTooltip);
            element.addEventListener('mouseleave', hideTooltip);
        }

        return () => {
            if (element) {
                // Убираем обработчики при размонтировании компонента
                element.removeEventListener('mouseenter', showTooltip);
                element.removeEventListener('mouseleave', hideTooltip);
            }
        };
    }, []);

    return {tooltip, position, tooltipRef};
};

