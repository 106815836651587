import React from 'react';
import styled from 'styled-components';
import {workActivity} from '../config';
import {Text} from '../../../fields';

export const WorkActivity = () => {
    return (
        <Content>
            {workActivity.map((item, index) => (
                <InfoRow key={index}>
                    <Text as={'p'} size={18} color={'var(--color-main)'} weight={400}>
                        <Text as={'span'} size={18} color={'var(--color-main)'} weight={400}>{item.dateFrom}</Text>
                        <Text as={'span'} size={18} color={'var(--color-main)'} weight={400}>{item.dateTo}</Text>
                    </Text>
                    <Text as={'p'} size={18} color={'var(--color-text)'} weight={400}>{item.text}</Text>
                </InfoRow>
            ))}
        </Content>
    );
};

const Content = styled.div`
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-self: baseline;
  gap: 25px;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;

  & p {
    display: inline-block;
    width: 50%;
  }

  & span {
    display: block;
  }
`;
