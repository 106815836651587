import React from 'react';
import styled from 'styled-components';
import {Text} from '../../fields';
import {useGetSchemasQuery, useLazyGetSchemaQuestionQuery} from '../../screens/home/store/home.api';
import {AppLoader} from '../appLoader';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {changeSidebarStep, setLevelData, setSelectQuestion, setViewFlow} from '../../screens/home/store/home.slice';
import {ListSects} from '../../screens/home/store/types';

export const Reports = () => {
    const dispatch = useAppDispatch();
    const {data: schemas} = useGetSchemasQuery();
    const [getQuestion, {isFetching}] = useLazyGetSchemaQuestionQuery();
    const {sidebarStep, levelData, viewFlow, selectSchemaQuestion} = useAppSelector(state => state.home);

    const handleSelectSchema = ({ID_Shema, name}: { ID_Shema: string, name: string }) => {
        getQuestion({ID_Shema})
            .unwrap()
            .then(({status, listSects}) => {
                if (status) {
                    dispatch(setViewFlow([...viewFlow, name]));
                    dispatch(setLevelData({levelData: listSects[0]?.children, totalReport: listSects[0]?.children}));
                    dispatch(changeSidebarStep(2));
                }
            }).catch((error) => {
        });
    };

    const handleSelectReport = (item: ListSects, level: number) => {
        dispatch(setSelectQuestion(item));
        if (item.children.length > 0) {
            dispatch(setViewFlow([...viewFlow, item.SectName]));
            dispatch(changeSidebarStep(level));
            dispatch(setLevelData({levelData: item.children}));
        }
    };

    const renderItems = () => {
        if (sidebarStep === 1) {
            return schemas?.map((item, indexSchema) => (
                <Item
                    key={`${item.value}-${indexSchema}`}
                    active={false}
                    onClick={() =>
                        handleSelectSchema({ID_Shema: item.value, name: item.label})}>
                    <Text
                        as={'p'}
                        weight={400}
                        size={18}
                        color={'var(color-main)'}
                    >
                        {item.label}
                    </Text>
                </Item>
            ));
        } else {
            return levelData.map((item: ListSects, indexQuestion) => (
                <Item
                    key={`${item.ID_Sect}-${indexQuestion}`}
                    active={selectSchemaQuestion?.ID_Sect === item.ID_Sect}
                    onClick={() => handleSelectReport(item, sidebarStep + 1)}>
                    <Text
                        as={'p'}
                        weight={400}
                        size={18}
                        color={'var(color-main)'}
                    >
                        {item.SectName}
                    </Text>
                </Item>
            ));
        }
    };

    return (
        <>
            {isFetching ? <AppLoader/> : null}
            <List>
                {renderItems()}
            </List>
        </>
    );
};

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div.withConfig({
    shouldForwardProp: prop => !['active'].includes(prop)
})<{ active: boolean }>`
  background-color: ${({active}) => active ? 'var(--background-active)' : 'var(--background)'};
  box-shadow: var(--box-shadow);
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid ${({active}) => active ? 'var(--color-main)' : 'transparent'};

  transition: transform 0.1s ease, box-shadow 0.1s ease;
  transform: translateY(0);

  &:active {
    transform: translateY(2px);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  }

  &:hover {
    cursor: pointer;
  }
`;
