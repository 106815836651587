import React from 'react';
import {Text} from '../../fields';
import {useAppDispatch, useAppSelector} from '../../hooks';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {changeRegionName, clearHome} from '../../screens/home/store/home.slice';


export const BreadCrumbsRegion = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {selectSubjectName, selectRegionName} = useAppSelector(state => state.home);

    const handleGoHome = () => {
        dispatch(clearHome());
        navigate('/');
    };

    const handleGoBack = () => {
        dispatch(changeRegionName(''));
        navigate('/');
    };


    return (
        <>
            <Text
                as={'p'}
                size={16}
                weight={600}
                style={{verticalAlign: 'middle'}}
                color={'var(--color-main)'}
            >
                <Crumb onClick={handleGoHome}>Россия </Crumb> {' > '}
                <Crumb onClick={handleGoBack}>{selectSubjectName}</Crumb>
                {selectRegionName !== '' ? (<>{' > '} <Crumb onClick={() => null}>{selectRegionName}</Crumb></>) : null}
            </Text>
        </>
    );
};

const Crumb = styled.span`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
