import React, {ReactNode} from 'react';
import {Header} from '../components/header';
import {Content, HeaderS, Inner, Wrapper} from './styled';
import {SimpleSidebar} from './SimpleSidebar';
import {BreadCrumbsPage} from '../components/breadCrumbs';
import {NextPage} from '../components/breadCrumbs/BreadCrumbsPage';

type SimpleLayoutProps = {
    children: ReactNode;
    rightSidebar: ReactNode;
    currentPage: NextPage | undefined
}

export const SimpleLayout = (
    {
        children,
        rightSidebar,
        currentPage
    }: SimpleLayoutProps
) => {
    return (
        <>
            <Wrapper>
                <HeaderS>
                    <Header/>
                </HeaderS>
                <BreadCrumbsPage nextPage={currentPage}/>
                <Inner>
                    <Content>
                        {children}
                    </Content>
                    <SimpleSidebar>
                        {rightSidebar}
                    </SimpleSidebar>
                </Inner>
            </Wrapper>
        </>
    );
};
