import React, {ReactNode, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {mediaWidth} from '../utils/mixins';
import {Icon} from '../assets/icons';
import {useAppDispatch, useAppSelector} from '../hooks';
import {changeSidebarStep, setLevelData, setViewFlow} from '../screens/home/store/home.slice';
import {filterByLevel} from '../helpers';
import {InputSearch} from '../fields';
import {YandexGpt} from '../components/yandexGPT';

type Direction = 'left' | 'right'
type SidebarType = {
    children: ReactNode,
    showBack: boolean;
    showSort: boolean;
    direction: Direction;
}

export const Sidebar = ({children, showBack, showSort, direction}: SidebarType) => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(true);
    const {sidebarStep, totalReport, viewFlow} = useAppSelector(state => state.home);

    const handleBack = () => {
        const isFirstStep = sidebarStep <= 1;

        const levelData = isFirstStep ? [] : filterByLevel(totalReport, sidebarStep - 1);
        const updateFlow = isFirstStep ? viewFlow : viewFlow.slice(0, -1);
        const newSidebarStep = isFirstStep ? 1 : sidebarStep - 1;

        dispatch(setViewFlow(updateFlow));
        dispatch(changeSidebarStep(newSidebarStep));
        dispatch(setLevelData({
            levelData,
            ...(isFirstStep && {totalReport: []})
        }));
    };
    return (
        <SidebarWrapper
            width={open ? 380 : 60}
            direction={direction}>
            <Header direction={direction}>
                {showSort && open ? (
                    <Toggle onClick={() => {
                    }}>
                        <Icon name={'sort'} width={30} height={30} color={'var(--color-main)'}/>
                    </Toggle>
                ) : null}
                {showSort ? <YandexGpt/> : null}
                <Toggle onClick={() => setOpen(!open)}>
                    <Icon name={'treeline'} width={30} height={30} color={'var(--color-main)'}/>
                </Toggle>
                {showBack ? <InputSearch/> : null}
                {showBack && open && sidebarStep > 1 ? (
                    <Toggle onClick={handleBack}>
                        <Icon name={'back'} width={30} height={30} color={'var(--color-main)'}/>
                    </Toggle>
                ) : null}


            </Header>
            {open
                ? <Content direction={direction}>
                    {children}
                </Content>
                : null}

        </SidebarWrapper>
    );
};

const SidebarWrapper = styled.div.withConfig({
    shouldForwardProp: prop => !['width', 'direction'].includes(prop)
})<{ width: number; direction: Direction }>`
  min-width: ${({width}) => width}px;
  width: min-content;
  transition: width 0.3s ease;
  ${({width}) => mediaWidth(width)}
  margin: ${({direction}) => direction === 'right' ? '0 0 0 auto' : '0'};
  flex-grow: 1;
`;

const Header = styled.div.withConfig({
    shouldForwardProp: prop => !['direction'].includes(prop)
})<{ direction: Direction }>`
  display: flex;
  justify-content: space-between;
  padding: ${({direction}) => direction === 'right' ? '10px 5px 10px 10px' : '10px 10px 10px 5px'};
  column-gap: 10px;

`;

const Toggle = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  transform: translateY(0);

  &:active {
    transform: translateY(2px);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Content = styled.div.withConfig({
    shouldForwardProp: prop => !['direction'].includes(prop)
})<{ direction: Direction }>`
  height: calc(100vh - 179px);
  overflow-y: auto;
  padding: ${({direction}) => direction === 'right' ? '10px 5px 10px 10px' : '10px 10px 10px 5px'};
  column-gap: 10px;
  width: 100%;
  transition: 1s;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in 0.1s forwards;
`;
