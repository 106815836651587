import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {Header} from '../components/header';

interface AuthLayoutProps {
    children: ReactNode;
}

export const AuthLayout = ({children}: AuthLayoutProps) => {
    return (
        <Wrapper>
            <HeaderS>
                <Header/>
            </HeaderS>
            <Inner>
                <Content>
                    {children}
                </Content>
            </Inner>

        </Wrapper>
    );
};

const Wrapper = styled.div`
  max-width: 1920px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 var(--pagging);
  display: flex;
  flex-direction: column;
`;

const Inner = styled.div`
  display: flex;
  padding: 10px 0;
`;

const HeaderS = styled.div`
  width: 100%;
  height: 89px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  box-shadow: var(--box-shadow);
  background-color: var(--background);
  padding: 12px 20px;
`;

const Content = styled.div`
  flex-grow: 1;
  height: calc(100vh - 119px);
  overflow-y: auto;
  padding: 10px;
`;
