import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 1920px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 var(--pagging);
  display: flex;
  flex-direction: column;
`;

export const Inner = styled.div`
  display: flex;
  padding: 10px 0;
  width: 100%;
`;

export const HeaderS = styled.div`
  width: calc(100% - 10px);
  height: 89px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  box-shadow: var(--box-shadow);
  background-color: var(--background);
  padding: 12px 20px;
  margin: 0 auto;
`;

export const Content = styled.div`
  height: calc(100vh - 160px);
  overflow: hidden;
  flex-grow: 1;
`;
