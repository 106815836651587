import React from 'react';
import {Reports} from '../../components/reports';
import {Main} from '../../layouts';
import {useAppSelector, useResize} from '../../hooks';
import {ContentPage, HeaderContentS, StaticPage, TitleBlock} from '../home/styled';
import {BreadCrumbsRegion} from '../../components/breadCrumbs';
import {InfoPage} from '../../components/infoPage';
import {RegionInfo} from '../../components/regionInfo';
import {Leaders} from '../../components/leaders';
import {Bars} from '../../components/bars';
import {formatNumber, groupByYears} from '../../helpers';
import {YearCard} from '../../components/yearCards/YearCards';
import styled from 'styled-components';
import {Text} from '../../fields';

export const Region = () => {
    const {ref, rect} = useResize<HTMLDivElement>();

    const {region, selectSchemaQuestion,} = useAppSelector(state => state.home);

    const years = groupByYears(region.data, false)
        .map((i, idx) =>
            ({name: i.year, info: formatNumber(i.total.toFixed()), indexItem: idx})) as YearCard[];
    return (
        <Main
            sidebarContent={<Reports/>}
            regionInfoContent={<RegionInfo/>}
        >
            <StaticPage>
                <HeaderContentS>
                    <BreadCrumbsRegion/>
                </HeaderContentS>
                <TitleBlock>
                    <InfoPage/>
                </TitleBlock>
            </StaticPage>
            <ContentPage ref={ref}>
                <Leaders years={years}/>
                <Container>
                    {selectSchemaQuestion ? <Text
                        as={'p'}
                        size={18}
                        weight={500}
                        color={'var(--color-text)'}
                    >
                        {selectSchemaQuestion.SectName}
                    </Text> : null}
                    <Bars years={years}/>
                </Container>
            </ContentPage>
        </Main>
    );
};

const Container = styled.div`
  padding: 20px 0;
`;
