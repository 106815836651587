import React from 'react';
import styled from 'styled-components';
import {mediaWidth} from '../utils/mixins';

export const Button = styled.button.withConfig({
    shouldForwardProp: prop => !['width', 'outline'].includes(prop)
})<{ width?: number; outline?: boolean; }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({width}) => width ? `${width}px` : '100%'};
  height: 40px;
  background-color: ${({outline}) => outline ? '#ffffff' : 'var(--color-main)'};
  border: 1px solid ${({outline}) => outline ? 'var(--color-main)' : 'transparent'};
  color: ${({outline}) => outline ? 'var(--color-main)' : '#ffffff'};
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  transform: translateY(0);

  font-family: var(--font);
  font-size: 16px;

  &:active {
    transform: translateY(2px);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  }

  ${({width}) => width ? mediaWidth(width) : `width: '100%'`};

  &:hover {

  }
`;
