import styled from 'styled-components';

export const YandexGPTButton = styled.button`
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  transition: transform 0.1s ease, box-shadow 0.1s ease;
  transform: translateY(0);

  &:active {
    transform: translateY(2px);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  }
`;
