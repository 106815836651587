import styled from 'styled-components';

export const StaticPage = styled.div`
  padding: 5px 10px;
`;

export const ContentPage = styled.div`
  height: calc(100vh - 285px);
  overflow-y: auto;
  padding: 5px 10px;
`;

export const HeaderContentS = styled.div`
  background-color: var(--background);
  border-radius: 12px;
  height: 40px;
  width: 100%;
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin-top: 5px;
`;

export const TitleBlock = styled.div`
  background-color: var(--background);
  border-radius: 12px;
  min-height: 81px;
  width: 100%;
  box-shadow: var(--box-shadow);
  padding: 12px;
  margin: 20px 0 10px;
`;

export const WrapperMap = styled.div`
  background-color: var(--background);
  border-radius: 12px;
  width: 100%;
  box-shadow: var(--box-shadow);
  padding: 12px;
  position: relative;
  z-index: 1;
`;

export const WrapperChart = styled.div`
  background-color: var(--background);
  border-radius: 12px;
  width: 100%;
  box-shadow: var(--box-shadow);
  padding: 12px;
  margin: 20px 0;
`;
