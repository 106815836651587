import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {useTooltip} from '../../hooks';

type TooltipProps = {
    children: ReactNode;
    tooltipText: string;
    style?: React.CSSProperties;
}

export const Tooltip = ({children, tooltipText, style}: TooltipProps) => {
    const {tooltip, position, tooltipRef} = useTooltip();

    return (
        <>
            <Content ref={tooltipRef} data-attr-tooltip={tooltipText} style={style}>
                {children}
            </Content>
            {tooltip && <TooltipS x={position.x} y={position.y}>{tooltip}</TooltipS>}
        </>
    );
};

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

// Стили для tooltip с fixed позиционированием
const TooltipS = styled.div<{ x: number; y: number }>`
  position: fixed; // Используем fixed для отображения поверх всего
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
  background: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  z-index: 9999;
  pointer-events: none;
  font-size: 12px;
  transition: opacity 0.2s ease-in-out;
`;
