import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Text} from '../../fields';
import {YearCard} from '../yearCards/YearCards';
import {useAppSelector} from '../../hooks';
import {useNavigate} from 'react-router-dom';

const leader1 = require('../../assets/images/directors/director-1.jpeg');
const leader2 = require('../../assets/images/directors/director-2.jpeg');
const leader3 = require('../../assets/images/directors/director-3.jpeg');
const leader4 = require('../../assets/images/directors/director-4.jpeg');

const leaders = [
    {img: leader1, id: 1, name: 'Иван', lastname: 'Федорович', surname: 'Гализдра'},
    {img: leader2, id: 2, name: 'Степан', lastname: 'Александрович', surname: 'Петров'},
    {img: leader3, id: 3, name: 'Светлана', lastname: 'Сергеевна', surname: 'Пугачева'},
    {img: leader4, id: 4, name: 'Илья', lastname: 'Иванович', surname: 'Муромец'},
];

function getRandomLeaders() {
    const count = Math.floor(Math.random() * 3) + 2; // Случайное число от 2 до 4
    const shuffled = [...leaders].sort(() => 0.5 - Math.random()); // Перемешиваем массив
    return shuffled.slice(0, count); // Берем первые count элементов
}

export const Leaders = ({years}: { years: YearCard[] }) => {
    const navigate = useNavigate();
    const {selectRegionName} = useAppSelector(state => state.home);
    const [currentLeaders, setCurrentLeaders] = useState(() => getRandomLeaders());

    // Обновляем лидеров при изменении selectRegionName
    useEffect(() => {
        setCurrentLeaders(getRandomLeaders());
    }, [selectRegionName]);

    const jumpToLeader = (id: number) => {
        navigate(`/leader/${id}`);
    };

    return (
        <Wrapper>
            <LeaderRow>
                {currentLeaders.map((item, idx) => {
                    return (
                        <LeaderCard key={idx} width={idx === 0 ? 20 : idx === 1 ? 56 : 24}
                                    onClick={() => jumpToLeader(item.id)}>
                            <LeaderCardImage>
                                <img src={item.img} alt={item.surname}/>
                            </LeaderCardImage>
                            <LeaderCardBody>
                                <Text
                                    as={'p'}
                                    size={16}
                                    weight={400}
                                    color={'var(--color-text)'}
                                >{item.surname}</Text>
                                <Text
                                    as={'p'}
                                    size={16}
                                    weight={400}
                                    color={'var(--color-text)'}
                                >{item.name}</Text>
                                <Text
                                    as={'p'}
                                    size={16}
                                    weight={400}
                                    color={'var(--color-text)'}
                                >{item.lastname}</Text>
                            </LeaderCardBody>
                        </LeaderCard>
                    );
                })}
            </LeaderRow>
            <Years>
                {years.map((item, idx) => <Text
                    as={'span'}
                    size={16}
                    weight={500}
                    color={'var(--color-text)'}
                >{item.name}</Text>)}
            </Years>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeaderRow = styled.div`
  display: flex;
  gap: 5px;
`;

const LeaderCard = styled.div.withConfig({
    shouldForwardProp: prop => !['width'].includes(prop)
})<{ width: number }>`
  width: ${({width}) => width}%;
  flex-grow: 1;
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  border-radius: 12px;
  overflow: hidden;
  height: 83px;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;

const LeaderCardImage = styled.div`
  min-width: 68px;
  width: min-content;
  height: 100%;
  border: 1px solid var(--background-active);
  border-radius: 12px;
  overflow: hidden;

  & img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

const LeaderCardBody = styled.div`
  padding: 5px 5px 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Years = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;
