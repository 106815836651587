import {useAppSelector} from './app';
import {useLayoutEffect, useState} from 'react';

export type UseCreateParamsProps = {
    FParentGeoIDs: string;
}
export const useCreateParams = ({FParentGeoIDs}: UseCreateParamsProps): { params: string; skip: boolean; } => {
    const {selectSchemaQuestion} = useAppSelector(state => state.home);
    const [params, setParams] = useState<string>('');

    useLayoutEffect(() => {
        let params: string = 'GroupYears=1';
        const ID_Sect = selectSchemaQuestion?.ID_Sect;

        if (FParentGeoIDs !== '') {
            params = FParentGeoIDs === 'rus'
                ? `${params}&Type=bySubjects`
                : `${params}&FParentGeoIDs=${FParentGeoIDs}`;
        }
        if (ID_Sect) {
            params = `${params}&ID_Sect=${ID_Sect}`;
        }
        setParams(params);
    }, [selectSchemaQuestion, FParentGeoIDs]);

    return {params, skip: selectSchemaQuestion === null};
};
