import React, {useState} from 'react';
import {AuthLayout} from '../../layouts/AuthLayout';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSignInMutation} from './store/auth.api';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {Icon} from '../../assets/icons';
import * as yup from 'yup';
import {SignForm} from './store/types';
import CryptoJS from 'crypto-js';
import {Button, Text} from '../../fields';
import {AppLoader} from '../../components/appLoader';

const schema = yup.object().shape({
    login: yup.string().required('Поле обязательно для заполнения'),
    pwd: yup.string().required('Поле обязательно для заполнения')
});

export const Login = () => {
    const navigate = useNavigate();
    const [signIn, {isLoading}] = useSignInMutation();
    const [showPass, setShowPass] = useState<boolean>(false);

    const {register, handleSubmit, formState: {errors}} = useForm<SignForm>({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (values: SignForm) => {
        const md5Hash = CryptoJS.MD5(values.pwd).toString().toUpperCase();

        signIn({login: values.login, pwd: md5Hash})
            .unwrap()
            .then(({status}) => {
                if (!status) {
                    navigate('/');
                }
            })
            .catch((error) => {
                toast.error(error.data.message, {
                    autoClose: false
                });
            });
    };

    return (
        <AuthLayout>
            <Content>
                {isLoading ? <AppLoader/> : null}
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Text
                        as={'p'}
                        size={16}
                        weight={600}
                        color={'var(--color-main)'}
                        center={true}
                    >Авторизиция</Text>
                    <Input>
                        <input {...register('login')} type={'text'}/>
                        <InputIcon>
                            <Icon name={'userBusiness'} width={24} height={24} color={'var(--color-main)'}/>
                        </InputIcon>
                    </Input>
                    <Input>
                        <input {...register('pwd')} type={showPass ? 'text' : 'password'}/>
                        <InputIcon onClick={() => setShowPass(!showPass)}>
                            {showPass
                                ? <Icon name={'unlock'} width={24} height={24} color={'var(--color-main)'}/>
                                : <Icon name={'lock'} width={24} height={24} color={'var(--color-main)'}/>}

                        </InputIcon>
                    </Input>
                    <Button type={'submit'}>Вход</Button>
                </Form>
            </Content>
        </AuthLayout>
    );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Form = styled.form`
  background-color: var(--background);
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  padding: 20px;
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.div`
  width: 100%;
  height: 40px;
  background-color: var(--background);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  display: flex;
  padding-left: 20px;

  & input {
    background-color: transparent;
    height: 100%;
    flex-grow: 1;
    font-family: var(--font);
    font-weight: 400;
    color: var(--color-text);

    &::placeholder {
      color: var(--color-text-light);
    }
  }
`;

const InputIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  width: 40px;
`;
