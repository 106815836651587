import React, {useEffect, useState} from 'react';
import {Bar, BarInfo, BraWrapper, ChartContainer, GridRow, InfoContainer, Label} from './styled';
import {YearCard} from '../yearCards/YearCards';
import {gradientArray} from '../chart/Chart';
import {Text} from '../../fields';
import {useAppSelector} from '../../hooks';

function getColorForValue(value: string) {
    const percentage = parseFloat(value.replace('%', '')) / 100;
    for (let i = 0; i < gradientArray.length; i++) {
        if (percentage >= gradientArray[i].value) {
            return gradientArray[i].color;
        }
    }
    return gradientArray[gradientArray.length - 1].color;  // Возвращаем последний цвет если процент меньше всех значений
}

function randomizeData(data: any, dataInfo: any): { data1: any, data2: any } {

    const data1 = data.map((item: any) => {
        const randomValue = (Math.random() * 100).toFixed(2);
        item.value = `${randomValue}%`;
        item.height = `${randomValue}%`;
        item.color = getColorForValue(item.value);
        return item;
    });

    const data2 = dataInfo.map((item: any) => {
        const randomValue = (Math.random() * (item.max - item.min) + item.min).toFixed(2);
        item.value = `${randomValue}%`;
        item.color = getColorForValue(item.value);
        return item;
    });

    return {data1, data2};
}


const gridLabels = [
    '90%',
    '80%',
    '70%',
    '60%',
    '50%',
    '40%',
    '30%',
    '20%',
    '10%',
];

const data = [
    {year: 2016, value: '15.54%', color: '', height: '15%'},
    {year: 2017, value: '33.17%', color: '', height: '33%', change: '-7%'},
    {year: 2018, value: '40.04%', color: '', height: '40%', change: '+21%'},
    {year: 2019, value: '84.04%', color: '', height: '84%', change: '+10%'},
    {year: 2020, value: '94.64%', color: '', height: '94%', change: '+1%'},
    {year: 2021, value: '34.64%', color: '', height: '34%', change: '+1%'},
    {year: 2022, value: '64.64%', color: '', height: '24%', change: '+0%'},
];

const dataInfo = [
    {max: 63, min: 15, range: 40, step: 5, color: '', value: '15.54%',},
    {max: 43, min: 5, range: 20, step: 8, color: '', value: '33.17%',},
    {max: 133, min: 55, range: 70, step: 3, color: '', value: '40.04%',},
    {max: 200, min: 120, range: 140, step: 2, color: '', value: '84.04%',},
    {max: 100, min: 90, range: 45, step: 5, color: '', value: '94.64%',},
    {max: 63, min: 40, range: 55, step: 4, color: '', value: '34.64%',},
    {max: 30, min: 15, range: 20, step: 9, color: '', value: '64.64%',},
];

export const Bars = ({years}: { years: YearCard[] }) => {
    const {selectRegionName} = useAppSelector(state => state.home);
    const [data1, setData1] = useState<any[]>(data);
    const [data2, setData2] = useState<any[]>(dataInfo);

    useEffect(() => {
        const {data1, data2} = randomizeData(data, dataInfo);
        console.log(data1);
        setData1(data1);
        setData2(data2);
    }, [selectRegionName]);

    data.forEach((item: any) => {
        item.color = getColorForValue(item.value);
    });
    dataInfo.forEach((item: any) => {
        item.color = getColorForValue(item.value);
    });

    return (
        <>
            <ChartContainer>
                {Array.from({length: 9}, (_, i) => i)
                    .map((i, indexGrid) => (
                        <GridRow key={indexGrid} text={gridLabels[i]}/>
                    ))}
                {data1?.map((item, index) => (
                    <>
                        <BraWrapper>
                            <Bar key={index} background={item.color} height={item.height}>
                                <Text
                                    as={'span'}
                                    color={'var(--color-text)'}
                                    size={16}
                                    weight={500}
                                >
                                    {item.value}
                                </Text>
                            </Bar>
                            <Label>
                                <Text
                                    as={'span'}
                                    color={'var(--color-text)'}
                                    size={16}
                                    weight={500}
                                >
                                    {item.year}
                                </Text>
                            </Label>
                        </BraWrapper>
                    </>
                ))}
            </ChartContainer>
            <Text
                as={'p'}
                size={18}
                weight={500}
                color={'var(--color-text)'}
            >
                Справка по региону
            </Text>
            <InfoContainer>
                {data2?.map((item, index) => (
                    <>
                        <BraWrapper>
                            <BarInfo key={index} background={item.color}>
                                <Text
                                    as={'span'}
                                    color={'var(--color-text)'}
                                    size={12}
                                    weight={500}
                                >
                                    Max: {item.max}
                                </Text>
                                <Text
                                    as={'span'}
                                    color={'var(--color-text)'}
                                    size={12}
                                    weight={500}
                                >
                                    Min: {item.min}
                                </Text>
                                <Text
                                    as={'span'}
                                    color={'var(--color-text)'}
                                    size={12}
                                    weight={500}
                                >
                                    Сред: {item.range}
                                </Text>
                                <Text
                                    as={'span'}
                                    color={'var(--color-text)'}
                                    size={12}
                                    weight={500}
                                >
                                    Место: {item.step}
                                </Text>
                            </BarInfo>
                        </BraWrapper>
                    </>
                ))}
            </InfoContainer>
        </>
    );
};


