import React from 'react';
import {useAppSelector} from '../hooks';
import {Navigate, Outlet} from 'react-router-dom';

export const Private = () => {
    const {isAuth} = useAppSelector(state => state.auth);
    return isAuth ? (
        <Outlet/>
    ) : (
        <Navigate to={'/login'}/>
    );
};
