import React, {useState} from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {Icon} from '../../assets/icons';
import {Text} from '../../fields';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {logout} from '../../screens/ayth/store/auth.slice';

export const Header = () => {
    const dispatch = useAppDispatch();
    const {isAuth, name} = useAppSelector(state => state.auth);
    const [visibleDrop, setVisibleDrop] = useState<boolean>(false);

    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <Block>
            <Logo>
                <NavLink to={'/'}>
                    <Icon name={'logo'} width={62} height={66}/>
                </NavLink>
                <Text
                    as={'span'}
                    size={18}
                    weight={600}
                    color={'var(--color-main)'}
                >
                    Информационная<br/>
                    система
                </Text>
            </Logo>
            {
                isAuth
                    ? <MenuS
                        onMouseEnter={() => setVisibleDrop(true)}
                        onMouseLeave={() => setVisibleDrop(false)}
                    >
                        <Text
                            as={'span'}
                            color={'var(--text-color)'}
                            size={16}
                            weight={600}
                        >{name}</Text>
                        <MenuDropS visible={visibleDrop}>
                            <Navigate>
                                <NavItem as={NavLink} to={'/'}>Профиль</NavItem>
                                <NavItem as={'div'} onClick={handleLogout}>Выход</NavItem>
                            </Navigate>
                        </MenuDropS>
                    </MenuS>
                    : null
            }
        </Block>
    );
};

const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;

  & span {
    display: inline-block;
    margin-left: 20px;
  }
`;

const MenuS = styled.div`
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

const MenuDropS = styled.div.withConfig({
    shouldForwardProp: prop => !['visible'].includes(prop)
})<{ visible: boolean }>`
  position: absolute;
  right: 0;
  width: 200px;
  border: 1px solid var(--light-color);
  border-radius: 4px;
  overflow: hidden;
  z-index: 2;
  background-color: #ffffff;
  visibility: ${({visible}) => visible ? 'visible' : 'hidden'};

  @media
  max-width(600px) {
    width: 100%;
  }
`;

const Navigate = styled.nav`
  display: flex;
  flex-direction: column;
`;

const NavItem = styled.div.attrs<{ as: any }>(({as}) => ({
    as: NavLink || 'div'
}))`
  padding: 7px 10px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: var(--text-color);

  &:hover {
    background-color: var(--overlay);
  }
`;
