import React from 'react';
import {Text} from '../../fields';
import {useAppSelector} from '../../hooks';

export const InfoPage = () => {
    const {viewFlow} = useAppSelector(state => state.home);
    return <>{
        viewFlow.map((item, index) => {
            if (viewFlow.length > 1 && index === 0) {
                return null;
            }
            return (
                <Text
                    key={index}
                    as={'p'}
                    size={16}
                    weight={400}
                    style={{marginBottom: '5px'}}
                    color={index > 0 ? 'var(--color-text)' : 'var(--color-text-light)'}
                >
                    {item}
                </Text>
            );
        })
    }</>;
};
