import {ListSects} from '../screens/home/store/types';

export const filterByLevel = (data: ListSects[], level: number): ListSects[] => {
    let result: ListSects[] = [];

    data.forEach(item => {
        if (item.Level === level) {
            result.push({
                ...item,
                // children: [] // Делаем children пустым массивом
            });
        }
        if (item.children && item.children.length > 0) {
            result = result.concat(filterByLevel(item.children, level));
        }
    });

    return result;
};
