import React from 'react';
import styled from 'styled-components';
import {Text} from '../../fields';
import {formatNumber} from '../../helpers';
import {DifferenceInfo} from '../diference';
import {YearCard} from '../yearCards/YearCards';
import {RegionData} from '../../screens/home/store/types';
import {Tooltip} from '../tooltip';
import {useNavigate} from 'react-router-dom';
import {Icon} from '../../assets/icons';
import {useAppDispatch} from '../../hooks';
import {changeRegionName} from '../../screens/home/store/home.slice';

type CollapseProps = {
    name: string;
    total: number;
    index: number;
    names: YearCard[];
    items: RegionData[]
    isOpen: boolean;
    setIsOpen: () => void;
    ObjGeoID: string;
}

export const RegionInfoCollapse = (
    {
        name,
        total,
        index,
        names,
        items,
        ObjGeoID,
        isOpen,
        setIsOpen
    }: CollapseProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const options = items.map((i) => ({
        name: i.SectorName,
        info: formatNumber(i.Value.toFixed()),
        indexItem: index,
        ObjGeoID: i.ObjGeoID
    }));

    const jumpToRegion = ({name, ObjGeoID}: { name: string, ObjGeoID: string }) => {
        dispatch(changeRegionName({selectRegionName: name}));
        navigate(`/region/${ObjGeoID.replace('relation/', '')}`);
    };

    const totalValues = items.map(i => i.Value).reduce((acc, i) => acc + i, 0);
    return (
        <CollapseWrapper>
            <Tooltip tooltipText={'динамика по области'}>
                <Toggle
                    onClick={() => {
                        setIsOpen();
                    }}>
                    <Text
                        as={'span'}
                        size={16}
                        weight={400}
                        color={'var(--color-main)'}
                    >
                        {name}
                    </Text>
                    <Values>
                        <Text
                            as={'span'}
                            size={18}
                            weight={400}
                            color={'var(--color-text)'}
                        >
                            {formatNumber(String(totalValues))}
                        </Text>
                        <DifferenceInfo info={String(total)} fontSize={10} index={index} options={names}/>
                        <Link onClick={() => jumpToRegion({ObjGeoID, name})}>
                            <Icon name={'arrow-right'} width={18} height={18} color={'var(--color-main)'}/>
                        </Link>
                    </Values>
                </Toggle>
            </Tooltip>

            {
                isOpen
                    ? (
                        <Body>
                            {items.map((item, idx) => (
                                <Row key={idx}>
                                    <Col>
                                        <Text
                                            as={'span'}
                                            size={12}
                                            weight={400}
                                            color={'var(--color-main)'}
                                        >
                                            {item.Year}
                                        </Text>
                                    </Col>
                                    <Col>
                                        <Text
                                            as={'span'}
                                            size={18}
                                            weight={500}
                                            color={'var(--color-text)'}
                                        >
                                            {formatNumber(item.Value.toFixed())}
                                        </Text>
                                    </Col>
                                    <Col>
                                        <DifferenceInfo
                                            info={item.Value.toFixed()}
                                            fontSize={8}
                                            index={idx}
                                            options={options}/>
                                    </Col>
                                </Row>
                            ))}
                        </Body>
                    ) : null
            }
        </CollapseWrapper>
    );
};

const Toggle = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CollapseWrapper = styled.div`
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  &:active {
    transform: translateY(2px);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1600px) {
    padding: 8px;
  }
`;

const Values = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Body = styled.div`
  margin-top: 5px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const Row = styled.div`
  padding: 6px;
  border-radius: 8px;
  background-color: var(--backgropund-page);
  width: 100%;
  display: flex;
  gap: 10px;
`;

const Col = styled.div`
  width: 33.3%;
  display: flex;
  align-items: center;

  &:last-of-type {
    justify-content: flex-end;
  }
`;

const Link = styled.button`
  text-decoration: none;
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  padding: 2px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
`;
