import {RegionData} from '../screens/home/store/types';

type GroupByYears = {
    year: string;
    items: RegionData[];
    index: number;
    total: number;
    Color: string;
    ObjGeoID: string;
    ObjParentGeoID: string;
}

export function groupByYears(data: RegionData[], isSort: boolean): GroupByYears[] {
    const groups: Record<string, RegionData[]> = {};

    data.forEach((item) => {
        const year = item.Year;
        if (!groups[year]) {
            groups[year] = [];
        }
        groups[year].push(item);
    });

    const result = Object.keys(groups).map((name, index) => {
        const items = groups[name];
        const total = items.map(i => i.Value).reduce((acc, i) => acc + i, 0);

        const {Color, ObjGeoID, ObjParentGeoID} = items[0];

        return {
            year: name,
            total,
            index,
            items,
            Color,
            ObjGeoID,
            ObjParentGeoID,
        };
    });

    if (isSort) {
        return result.sort((a, b) => b.total - a.total);
    } else {
        return result;
    }
}
