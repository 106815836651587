import {css} from 'styled-components';

export const mediaWidth = (width: number) => css`
  @media (max-width: 1600px) {
    width: min-content;
    min-width: ${width * 0.8}px; /* 80% от исходного размера */
  }
  @media (max-width: 1440px) {
    width: min-content;
    min-width: ${width * 0.7}px; /* 70% от исходного размера */
  }
  @media (max-width: 1200px) {
    width: min-content;
    min-width: ${width * 0.6}px; /* 60% от исходного размера */
  }
  @media (max-width: 1024px) {
    width: min-content;
    min-width: ${width * 0.5}px; /* 50% от исходного размера */
  }
  @media (max-width: 991px) {
    width: min-content;
    min-width: ${width * 0.4}px; /* 40% от исходного размера */
  }
`;

export const textRatio = (size: number) => css`
  @media (max-width: 1600px) {
    font-size: ${size * 0.7}px; /* 80% от исходного размера */
    line-height: ${size * 0.7}px; /* 80% от исходного размера */
  }
  @media (max-width: 1440px) {
    font-size: ${size * 0.6}px; /* 70% от исходного размера */
    line-height: ${size * 0.6}px; /* 70% от исходного размера */
  }
  @media (max-width: 1200px) {
    font-size: ${size * 0.5}px; /* 60% от исходного размера */
    line-height: ${size * 0.5}px; /* 60% от исходного размера */
  }
  @media (max-width: 1024px) {
    font-size: ${size * 0.4}px; /* 50% от исходного размера */
    line-height: ${size * 0.4}px; /* 50% от исходного размера */
  }
  @media (max-width: 991px) {
    font-size: ${size * 0.3}px; /* 40% от исходного размера */
    line-height: ${size * 0.3}px; /* 40% от исходного размера */
  }
`;
