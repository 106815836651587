import React from 'react';
import styled from 'styled-components';
import {Icon} from '../assets/icons';

export const InputSearch = () => {
    return (
        <Input>
            <input/>
            <ButtonIcon>
                <Icon name={'search'} width={20} height={20} color={'var(--color-main)'}/>
            </ButtonIcon>
        </Input>
    );
};

const Input = styled.div`
  width: 100%;
  height: 40px;
  background-color: var(--background);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 1px;

  & input {
    height: 100%;
    width: calc(100% - 40px);
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: 500;
    font-family: var(--font);
    font-size: 16px;
    color: var(--color-text);
    padding-left: 10px;

    &::placeholder {
      color: var(--color-text-light);
    }
  }
`;

const ButtonIcon = styled.button`
  height: 38px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`;
