import {RegionData} from '../screens/home/store/types';

type GroupByName = {
    name: string;
    items: RegionData[];
    index: number;
    total: number;
    ObjGeoID: string; // Добавляем поле для ObjGeoID
};

export function groupByName(data: RegionData[], isSort: boolean): GroupByName[] {
    const groups: Record<string, RegionData[]> = {};

    data.forEach((item) => {
        const name = item.ObjName;
        if (!groups[name]) {
            groups[name] = [];
        }
        groups[name].push(item);
    });

    const result = Object.keys(groups).map((name, index) => {
        const total = groups[name].map((i) => i.Value).reduce((acc, i) => acc + i, 0);

        // Используем ObjGeoID первого элемента группы
        const ObjGeoID = groups[name][0]?.ObjGeoID || '';

        return {
            name,
            total,
            index,
            items: groups[name],
            ObjGeoID, // Добавляем geoID в возвращаемый объект
        };
    });

    return isSort ? result.sort((a, b) => b.total - a.total) : result;
}
