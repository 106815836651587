import React, {ReactNode} from 'react';
import {Sidebar} from './Sidebar';
import {Header} from '../components/header';
import {useAppSelector, useCreateParams} from '../hooks';
import {useGetRegionsParamsQuery} from '../screens/home/store/home.api';
import {AppLoader} from '../components/appLoader';
import {Content, HeaderS, Inner, Wrapper} from './styled';
import {BreadCrumbsPage} from '../components/breadCrumbs';

interface MainProps {
    sidebarContent: ReactNode;
    regionInfoContent: ReactNode;
    children: ReactNode;
}

export const Main = ({children, sidebarContent, regionInfoContent}: MainProps) => {
    const {regionId} = useAppSelector(state => state.home);
    const {params, skip} = useCreateParams({FParentGeoIDs: regionId ? `${regionId}` : '0'});

    const {isFetching} = useGetRegionsParamsQuery({params}, {skip, refetchOnMountOrArgChange: true});
    return (
        <>
            {isFetching ? <AppLoader/> : null}
            <Wrapper>
                <HeaderS>
                    <Header/>
                </HeaderS>
                <BreadCrumbsPage nextPage={undefined}/>
                <Inner>
                    <Sidebar showBack={true} showSort={false} direction={'left'}>
                        {sidebarContent}
                    </Sidebar>
                    <Content>
                        {children}
                    </Content>
                    <Sidebar showBack={false} showSort={true} direction={'right'}>
                        {regionInfoContent}
                    </Sidebar>
                </Inner>
            </Wrapper>
        </>
    );
};
