import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {mediaWidth} from '../utils/mixins';

type SimpleSidebarProps = {
    children: ReactNode
}

export const SimpleSidebar = (
    {
        children
    }: SimpleSidebarProps
) => {
    return (
        <SidebarWrapper>
            <Content>
                {children}
            </Content>
        </SidebarWrapper>
    );
};

const SidebarWrapper = styled.div`
  min-width: 380px;
  width: min-content;
  transition: width 0.3s ease;
  ${() => mediaWidth(380)}
  margin: 0 0 0 auto;
  flex-grow: 1;
`;


const Content = styled.div`
  overflow-y: auto;
  padding: 10px 5px 10px 10px;
  width: 100%;
  height: 100%;
`;
