import React, {useEffect} from 'react';
import {drawGrid, findBreakPoints} from './lib';
import {useAppSelector} from '../../hooks';
import {groupByName} from '../../helpers';
import styled from 'styled-components';
import {Text} from '../../fields';

export type ChartData = { value: number; year: number };

interface ChartProps {
    width: number;
    isMultiple: boolean;
}

export const gradientArray = [
    {value: 1, color: '#4DA34D'},
    {value: 0.75, color: '#97BD4B'},
    {value: 0.50, color: '#DEC14C'},
    {value: 0.25, color: '#EA9958'},
    {value: 0, color: '#DF5A5A'},
];

function groupValuesByYear(data: ChartData[][]): ChartData[] {
    // Объединяем все вложенные массивы в один
    const flattenedData = data.flat();

    // Группируем значения по годам
    const groupedData = flattenedData.reduce((acc, item) => {
        if (!acc[item.year]) {
            acc[item.year] = 0; // Инициализация суммы для года
        }
        acc[item.year] += item.value; // Добавление значения
        return acc;
    }, {} as Record<number, number>);

    // Преобразуем в массив объектов ChartData
    return Object.entries(groupedData).map(([year, value]) => ({
        year: Number(year),
        value
    }));
}

export const Chart = ({width, isMultiple}: ChartProps) => {
    const {region, selectedGroupIndex} = useAppSelector(state => state.home);
    const group = groupByName(region?.data ?? [], true);
    const chartData = group.map(i => i.items.flatMap(i => ({
        value: Number(i.Value.toFixed()),
        year: i.Year
    }))) as any;

    useEffect(() => {
        if (region.data.length === 0) {
            return;
        }
        const data = selectedGroupIndex !== null ? [chartData[selectedGroupIndex]] : [groupValuesByYear(chartData)];
        const canvas: HTMLCanvasElement = document.getElementById('myCanvas') as HTMLCanvasElement;
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;

        const padding = 60;
        const width = canvas.width - padding * 2;
        const height = canvas.height - padding * 2;

        // Вычисляем общий minValue и maxValue
        let globalMinValue = Number.MAX_VALUE;
        let globalMaxValue = -Number.MAX_VALUE;

        const allData = isMultiple ? (data as ChartData[][]) : [data as ChartData[]];

        allData.forEach((dataset) => {
            const values = dataset.map((d) => d.value);
            globalMinValue = Math.min(globalMinValue, ...values);
            globalMaxValue = Math.max(globalMaxValue, ...values);
        });

        const valueRange = globalMaxValue - globalMinValue;

        // Очистка canvas перед каждым рендерингом
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Рисуем сетку и оси
        drawGrid(canvas, ctx, padding, width, height, 0, allData[0].length, globalMinValue, globalMaxValue);

        ctx.beginPath();
        ctx.moveTo(padding, padding);
        ctx.lineTo(padding, canvas.height - padding); // Y-ось
        ctx.lineTo(canvas.width - padding, canvas.height - padding); // X-ось
        ctx.stroke();

        // Подписи для оси Y
        ctx.save();
        ctx.textAlign = 'right';
        ctx.textBaseline = 'middle';
        ctx.font = '15px Inter';
        ctx.fillStyle = 'black';

        for (let value = Math.floor(globalMinValue); value <= Math.ceil(globalMaxValue); value += valueRange / 10) {
            const y = canvas.height - padding - ((value - globalMinValue) / valueRange) * height;
            ctx.fillText(value.toFixed(0), padding - 10, y);
        }
        ctx.restore();

        // Рисуем графики
        allData.forEach((dataset, index) => {
            const years = dataset.map((d) => d.year);
            const minYear = Math.min(...years);
            const maxYear = Math.max(...years);
            const yearStep = width / (maxYear - minYear);

            // Создание градиента от нижней к верхней части
            const gradient = ctx.createLinearGradient(padding, canvas.height - padding, padding, padding);
            for (const gradientItem of gradientArray) {
                gradient.addColorStop(gradientItem.value, gradientItem.color);
            }

            ctx.lineWidth = 2;

            // Рисуем плавный график с использованием Bézier кривых
            ctx.beginPath();
            for (let i = 0; i < dataset.length - 1; i++) {
                const x1 = padding + (dataset[i].year - minYear) * yearStep;
                const y1 = canvas.height - padding - ((dataset[i].value - globalMinValue) / valueRange) * height;

                const x2 = padding + (dataset[i + 1].year - minYear) * yearStep;
                const y2 = canvas.height - padding - ((dataset[i + 1].value - globalMinValue) / valueRange) * height;

                // Определяем контрольные точки для Bézier-кривой
                const cpX1 = x1 + (x2 - x1) / 3;
                const cpY1 = y1;
                const cpX2 = x2 - (x2 - x1) / 3;
                const cpY2 = y2;

                if (i === 0) {
                    ctx.moveTo(x1, y1); // Начальная точка
                }

                // Рисуем Bézier кривую
                ctx.bezierCurveTo(cpX1, cpY1, cpX2, cpY2, x2, y2);
            }
            ctx.strokeStyle = gradient; // Применяем градиент
            ctx.stroke();

            // Подписи для оси X
            ctx.textBaseline = 'middle';
            ctx.font = '14px Inter';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            dataset.forEach((d) => {
                const x = padding + (d.year - minYear) * yearStep;
                const y = canvas.height - padding + 20;
                ctx.fillText(d.year?.toString(), x, y); // Подпись года
            });

            // Рисуем точки излома
            const breakPoints = findBreakPoints(dataset, 1); // Порог 1

            const lineGradient = ctx.createLinearGradient(padding, canvas.height - padding, padding, padding);
            for (const gradientItem of gradientArray) {
                lineGradient.addColorStop(gradientItem.value, gradientItem.color);
            }

            ctx.fillStyle = lineGradient; //`hsl(${index * 60}, 70%, 50%)`; // Точки излома в цвете графика
            breakPoints.forEach((i: number) => {
                const x = padding + (dataset[i].year - minYear) * yearStep;
                const y = canvas.height - padding - ((dataset[i].value - globalMinValue) / valueRange) * height;

                ctx.beginPath();
                ctx.arc(x, y, 5, 0, Math.PI * 2); // Точка излома
                ctx.fill();
            });
        });
    }, [region, isMultiple, selectedGroupIndex, width]);

    console.log(typeof width);
    return region.data.length === 0
        ? (<Content>
            <InfoBlock>
                <Text
                    as={'p'}
                    size={16}
                    weight={500}
                    color={'var(--color-text-light)'}
                >
                    Выберите<br/>показатель
                </Text>
            </InfoBlock>
        </Content>)
        : <Content>
            <InfoBlock>
                <Text
                    as={'p'}
                    size={16}
                    weight={500}
                    color={'var(--color-text)'}
                >
                    {selectedGroupIndex !== null
                        ? `${group[selectedGroupIndex].name} - динамика показателя` : 'Общая динамика'}
                </Text>
            </InfoBlock>
            <canvas id="myCanvas" width={String(width)} height="300"></canvas>
        </Content>;
};

const Content = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & canvas {
    margin-left: auto;
    display: block;
    width: 100%;
  }
`;

const InfoBlock = styled.div`
  width: 100%;
`;
