import React from 'react';
import {Text} from '../../fields';
import styled from 'styled-components';
import {YearCard} from '../yearCards/YearCards';

type DifferenceInfoProps = {
    info: string;
    index: number;
    options: YearCard[];
    fontSize: number;
}

function calculatePercentageDifference(current: number, previous: number) {
    if (previous === 0) return 0; // Защита от деления на ноль
    return ((current - previous) / previous) * 100;
}

export const DifferenceInfo = ({info, options, fontSize, index}: DifferenceInfoProps) => {

    const currentValue = parseInt(info?.replace(/\s/g, ''), 10); // Убираем пробелы и преобразуем в число
    const previousValue = index > 0 ? parseInt(options[index - 1].info?.replace(/\s/g, ''), 10) : null;

    const percentageDifference = previousValue !== null
        ? +calculatePercentageDifference(currentValue, previousValue).toFixed(1)
        : null;


    return (
        <>
            {percentageDifference !== null
                ? <Info>
                    <Text
                        as={'p'}
                        size={10}
                        weight={600}
                        color={percentageDifference > 0 ? 'var(--color-success)' : 'var(--color-error)'}>
                        {percentageDifference > 0 ? `+${percentageDifference}%` : `${percentageDifference}%`}
                    </Text>
                    {percentageDifference > 0
                        ? <GreenTriangleUp size={fontSize}/>
                        : <RedTriangleDown size={fontSize}/>
                    }
                </Info>
                : null}
        </>
    );
};

const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const RedTriangleDown = styled.span.withConfig({
    shouldForwardProp: prop => !['size'].includes(prop)
})<{ size: number }>`
  width: 0;
  height: 0;
  border-left: ${({size}) => size / 3 * 2}px solid transparent;
  border-right: ${({size}) => size / 3 * 2}px solid transparent;
  border-top: ${({size}) => size}px solid var(--color-error);
`;

export const GreenTriangleUp = styled.span.withConfig({
    shouldForwardProp: prop => !['size'].includes(prop)
})<{ size: number }>`
  width: 0;
  height: 0;
  border-left: ${({size}) => size / 3 * 2}px solid transparent;
  border-right: ${({size}) => size / 3 * 2}px solid transparent;
  border-bottom: ${({size}) => size}px solid var(--color-success);
`;
