import React from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {textRatio} from '../../utils/mixins';
import {Text} from '../../fields';

export type NextPage = {
    name: string;
    path: string;
}

type BreadCrumbsPageProps = {
    nextPage?: NextPage;
}

export const BreadCrumbsPage = (
    {
        nextPage
    }: BreadCrumbsPageProps
) => {
    return (
        <Row>
            <Link to={'/'}>Главная</Link>
            {nextPage ?
                <>
                    <Text
                        as={'p'}
                        size={16}
                        weight={600}
                        style={{margin: '0 3px'}}
                        color={'var(--color-text)'}
                    >{' > '}
                    </Text>
                    <Text
                        as={'p'}
                        size={16}
                        weight={600}
                        color={'var(--color-text)'}
                    >{nextPage.name}
                    </Text>
                </> : null
            }
        </Row>
    );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 8px 0;
`;

const Link = styled(NavLink)`
  color: var(--color-text-medium);
  font-family: var(--font);
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
  ${textRatio(16)};

  text-decoration: none;
`;
