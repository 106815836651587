import styled from 'styled-components';

const excludeAttr = [
    'as',
    'size',
    'weight',
    'color',
    'center'
];
type TextTypes = {
    size: number;
    weight: 400 | 500 | 600;
    color: string;
    center?: boolean;
}

export const Text = styled.p.attrs(({as}) => ({
    as: as || 'p'
})).withConfig({
    shouldForwardProp: prop => !excludeAttr.includes(prop)
})<TextTypes>`
  font-family: var(--font);
  font-size: ${({size}) => size}px;
  line-height: ${({size}) => size}px;
  font-weight: ${({weight}) => weight};
  color: ${({color}) => color};
  text-align: ${({center}) => center ? 'center' : 'inherit'};
  margin: 0;

  @media (max-width: 1600px) {
    font-size: ${({size}) => size * 0.7}px; /* 80% от исходного размера */
    line-height: ${({size}) => size * 0.7}px; /* 80% от исходного размера */
  }
  @media (max-width: 1440px) {
    font-size: ${({size}) => size * 0.6}px; /* 70% от исходного размера */
    line-height: ${({size}) => size * 0.6}px; /* 70% от исходного размера */
  }
  @media (max-width: 1200px) {
    font-size: ${({size}) => size * 0.5}px; /* 60% от исходного размера */
    line-height: ${({size}) => size * 0.5}px; /* 60% от исходного размера */
  }
  @media (max-width: 1024px) {
    font-size: ${({size}) => size * 0.4}px; /* 50% от исходного размера */
    line-height: ${({size}) => size * 0.4}px; /* 50% от исходного размера */
  }
  @media (max-width: 991px) {
    font-size: ${({size}) => size * 0.3}px; /* 40% от исходного размера */
    line-height: ${({size}) => size * 0.3}px; /* 40% от исходного размера */
  }
`;
