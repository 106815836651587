import {createSlice} from '@reduxjs/toolkit';
import {BreadCrumbs, homeStore, RegionDataRes} from './types';
import {homeApi} from './home.api';

export const defaultBreadCrumbs: BreadCrumbs = {path: '/', name: 'Главная'};
export const defaultViewFlow: string[] = ['Выберите тип показателя'];
export const defaultRegion: RegionDataRes = {
    data: [],
    max: 0,
    min: 0,
    totalValues: 0
};
const initialState: homeStore = {
    regionId: 'rus',
    hoverRegionName: '',
    selectRegionName: '',
    selectSubjectName: '',
    selectSchemaQuestion: null,
    breadCrumbs: [defaultBreadCrumbs],
    sidebarStep: 1,
    levelData: [],
    totalReport: [],
    region: defaultRegion,
    selectedGroupIndex: null,
    yearIndex: 7,
    viewFlow: defaultViewFlow
};

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        changeSubjectName: (state, {payload}) => {
            state.selectSubjectName = payload.selectSubjectName;
        },
        changeRegionName: (state, {payload}) => {
            state.selectRegionName = payload.selectRegionName;
        },
        changeHoverRegionName: (state, {payload}) => {
            state.hoverRegionName = payload.hoverRegionName;
        },
        changeRegionId: (state, {payload}) => {
            state.regionId = payload.regionId;
        },
        changeBreadCrumbs: (state, {payload}) => {
            state.breadCrumbs = payload;
        },
        setSelectQuestion: (state, {payload}) => {
            state.selectSchemaQuestion = payload;
        },
        changeSidebarStep: (state, {payload}) => {
            state.sidebarStep = payload;
        },
        setSelectedGroupIndex: (state, {payload}) => {
            state.selectedGroupIndex = payload;
        },
        setLevelData: (state, {payload}) => {
            state.levelData = payload.levelData;
            if (payload.totalReport) {
                state.totalReport = payload.totalReport;
            }
        },
        setViewFlow: (state, {payload}) => {
            state.viewFlow = payload;
        },
        setYearIndex: (state, {payload}) => {
            state.yearIndex = payload;
        },
        clearHome: (state) => {
            state.selectSubjectName = '';
            state.selectRegionName = '';
            state.regionId = 'rus';
            state.sidebarStep = 1;
            state.levelData = [];
            state.region = defaultRegion;
            state.selectSchemaQuestion = null;
            state.selectedGroupIndex = null;
            state.viewFlow = defaultViewFlow;
            state.yearIndex = 7;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            homeApi.endpoints.getRegionsParams.matchFulfilled,
            (state, {payload}) => {
                state.region = payload;
            }
        );
    }
});

export const {
    changeHoverRegionName,
    changeRegionId,
    changeRegionName,
    changeSubjectName,
    clearHome,
    changeSidebarStep,
    setLevelData,
    setSelectQuestion,
    setSelectedGroupIndex,
    setViewFlow,
    setYearIndex
} = homeSlice.actions;

export default homeSlice.reducer;
