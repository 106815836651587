import React from 'react';
import styled from 'styled-components';
import {Button, Text} from '../../../fields';

interface ToggleBlockProps {
    toggleIndex: number;
    getIndex: (index: number) => void;
}

export const ToggleBlock = (
    {
        toggleIndex, getIndex
    }: ToggleBlockProps) => {
    return (
        <Content>
            <Text
                as={'p'}
                size={18}
                color={'var(--color-text)'}
                weight={500}
            >
                Сравнение с регионами по стране
            </Text>
            <Buttons>
                <Button outline={true} onClick={() => getIndex(0)}>Что лучше</Button>
                <Button outline={true} onClick={() => getIndex(1)}>Что хуже</Button>
            </Buttons>
            <Text
                as={'p'}
                size={18}
                color={'var(--color-text)'}
                weight={500}
            >
                Сравнение с предыдущими главами региона
            </Text>
            <Buttons>
                <Button outline={true} onClick={() => getIndex(2)}>Что лучше</Button>
                <Button outline={true} onClick={() => getIndex(3)}>Что хуже</Button>
            </Buttons>
        </Content>
    );
};

export const Content = styled.div`
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
