import {createSlice} from '@reduxjs/toolkit';
import {AuthStore} from './types';
import {authApi} from './auth.api';

const initialState: AuthStore = {
    isAuth: false,
    id_con: null,
    name: '',
    email: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: () => {
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            authApi.endpoints.signIn.matchFulfilled,
            (state, {payload}) => {
                state.isAuth = payload.status;
                state.id_con = payload.user.id_con;
                state.name = payload.user.name;
                state.email = payload.user.email;
            }
        );
    }
});

export const {logout} = authSlice.actions;
export default authSlice.reducer;
