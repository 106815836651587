import React from 'react';
import styled from 'styled-components';
import {Leader} from '../LeaderPage';
import {Text} from '../../../fields';

export const LeaderCard = (
    {
        img, name, surname, lastname, id
    }: Leader
) => {
    return (
        <Card>
            <Image>
                <img src={img} alt={'leader'}/>
            </Image>
            <CardBody>
                <Text as={'p'} size={28} color={'var(--color-text)'} weight={500}>
                    {[surname, name, lastname].join(' ')}
                </Text>
                <InfoRow>
                    <Text as={'p'} size={18} color={'var(--color-main)'} weight={400}>Должность:</Text>
                    <Text as={'p'} size={18} color={'var(--color-text)'} weight={400}>Губернатор области</Text>
                </InfoRow>
                <InfoRow>
                    <Text as={'p'} size={18} color={'var(--color-main)'} weight={400}>Национальность:</Text>
                    <Text as={'p'} size={18} color={'var(--color-text)'} weight={400}>Русский</Text>
                </InfoRow>
                <InfoRow>
                    <Text as={'p'} size={18} color={'var(--color-main)'} weight={400}>Образование:</Text>
                    <Text as={'p'} size={18} color={'var(--color-text)'} weight={400}>Высшее</Text>
                </InfoRow>
                <InfoRow>
                    <Text as={'p'} size={18} color={'var(--color-main)'} weight={400}>Специальность:</Text>
                    <Text as={'p'} size={18} color={'var(--color-text)'} weight={400}>Юрист</Text>
                </InfoRow>

            </CardBody>
        </Card>
    );
};


const Card = styled.div`
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  border-radius: 12px;
  min-height: 307px;
  padding: 20px;
  display: flex;
`;

const Image = styled.div`
  width: 150px;
  height: 200px;
  border-radius: 12px;
  overflow: hidden;

  & img {
    width: 100%;
    //height: 100%;
    object-position: center center;
    object-fit: cover;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-left: 20px;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;

  & p {
    width: 50%;
  }
`;
