import React from 'react';
import {Text} from '../../fields';
import {useAppDispatch, useAppSelector} from '../../hooks';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {clearHome} from '../../screens/home/store/home.slice';


export const BreadCrumbsHome = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {hoverRegionName, selectSubjectName, selectRegionName} = useAppSelector(state => state.home);

    const handleGoHome = () => {
        dispatch(clearHome());
        navigate('/');
    };

    return (
        <>
            <Text
                as={'p'}
                size={16}
                weight={600}
                color={'var(--color-main)'}
            >
                <Crumb onClick={handleGoHome}>Россия </Crumb>
                {`${selectSubjectName ? '> ' + selectSubjectName : ''} ${hoverRegionName !== '' ? '>' + hoverRegionName : ''}`}
            </Text>
        </>
    );
};

const Crumb = styled.span`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
