import {api} from '../../../store/api';
import {ListOptions, ListRegions, ListSects, RegionData, RegionDataRes} from './types';
import {Option} from '../../../types';

function transformToListSects(data: any): ListSects[] {
    if (!data || typeof data !== 'object') {
        return [];
    }

    return Object.values(data).map((item: any) => ({
        ID_Sect: item.ID_Sect,
        ID_Parent: item.ID_Parent,
        SectName: item.SectName,
        ID_Type: item.ID_Type,
        TypeName: item.TypeName,
        FuncList: item.FuncList,
        EI: item.EI,
        List: item.List,
        Rem: item.Rem,
        Level: item.Level,
        children: item.children ? transformToListSects(item.children) : []
    }));
}

export const findMinMaxValue = (data: RegionData[]): { min: number, max: number } => {

    if (data.length > 0) {
        return data.reduce(
            (acc, obj) => {
                if (obj.Value < acc.min) acc.min = obj.Value;
                if (obj.Value > acc.max) acc.max = obj.Value;
                return acc;
            },
            {min: data[0].Value, max: data[0].Value}
        );
    } else {
        return {min: 0, max: 0};
    }
};

export const homeApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSchemas: build.query<Option[], void>({
            query: () => ({
                url: `/listData/listSchemes`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({listSchemes}) => {
                return listSchemes.map((i: ListOptions) => ({label: i.Name, value: i.ID_Shema}));
            }
        }),
        getSchemaQuestion: build.query<{ status: boolean; listSects: ListSects[] }, { ID_Shema: string }>({
            query: ({ID_Shema}) => ({
                url: `/listData/listSectsTree?ID_Shema=${ID_Shema}`
            }),
            transformResponse: ({status, listSects}) => {
                const list = transformToListSects(listSects);
                return {status: status, listSects: list};
            }
        }),
        getNameRegions: build.query<Option[], void>({
            query: () => ({
                url: '/listData/listRegions',
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({listRegions}) => {
                return listRegions.map((i: ListRegions) => ({
                    label: i.Name,
                    value: i.ID_Tab
                })).filter((i: Option) => i.label !== '_ТЕСТ');
            }
        }),
        getRegionsParams: build.query<RegionDataRes, { params: string }>({
            query: ({params}) => ({
                url: `/reports/kbrParamValues?${params}`
            }),
            transformResponse: ({response}) => {
                const totalValues = response.map((i: RegionData) => i?.Value).reduce((acc: number, i: number) => acc + i, 0);
                const {min, max} = findMinMaxValue(response);
                return {data: response, totalValues, min, max};
            }
        })
    }), overrideExisting: true
});

export const {
    useGetSchemasQuery,
    useLazyGetSchemaQuestionQuery,
    useGetRegionsParamsQuery,
    useGetNameRegionsQuery,
} = homeApi;
