import React from 'react';
import {Main} from '../../layouts';
import {ContentPage, HeaderContentS, StaticPage, TitleBlock, WrapperChart, WrapperMap} from './styled';
import {Reports} from '../../components/reports';
import {Map} from '../../components/map';
import {BreadCrumbsHome} from '../../components/breadCrumbs';
import {InfoPage} from '../../components/infoPage';
import {YearCards} from '../../components/yearCards';
import {RegionInfo} from '../../components/regionInfo';
import {Chart} from '../../components/chart';
import {useResize} from '../../hooks';

export const Home = () => {
    const {ref, rect} = useResize<HTMLDivElement>();

    return (
        <Main
            sidebarContent={<Reports/>}
            regionInfoContent={<RegionInfo/>}
        >
            <StaticPage>
                <HeaderContentS>
                    <BreadCrumbsHome/>
                </HeaderContentS>
                <TitleBlock>
                    <InfoPage/>
                </TitleBlock>
            </StaticPage>

            <ContentPage ref={ref}>
                <YearCards/>
                <WrapperMap>
                    <Map onlyRu={false}/>
                </WrapperMap>
                <WrapperChart>
                    <Chart width={Number(rect?.width) - 40} isMultiple={true}/>
                </WrapperChart>
            </ContentPage>
        </Main>
    );
};
