import React from 'react';
import {YandexGPTButton} from './styled';
import {Icon} from '../../assets/icons';

export const YandexGpt = () => {
    return (
        <YandexGPTButton>
            <Icon name={'yandex-GPT'} width={100} height={30} color={'var(--color-main)'}/>
        </YandexGPTButton>
    );
};
